<script setup>
import { computed } from 'vue'
const { title, text, imgName } = defineProps(['title', 'text', 'imgName'])

const imageSrc = computed(() => {
  return new URL(`../../assets/images/${imgName}.png`, import.meta.url).href
})
</script>

<template>
  <div class="container">
    <div class="img-container">
      <img :src="imageSrc" :alt="imgName" />
    </div>
    <p class="heading-5">{{ title }}</p>
    <p class="p-md">{{ text }}</p>
    <div class="btn btn-primary label-md cnt-btn mt-16" @click="$emit('cta')">Continue</div>
  </div>
</template>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  padding: 0px 16px;
  max-width: 428px;
}

.img-container {
  min-height: 250px;
}

img {
  width: 100%;
  max-width: 400px;
}

button {
  display: inline-block;
}

.heading-5 {
  margin-bottom: 16px;
}
</style>
