import { defineStore } from 'pinia'
import { ref, watch } from 'vue'
import { loginActions, setUserData as setUserRemote } from '@/helpers/userManager.js'
import { sendUserEvent } from '../helpers/cerebro'
import { useProductStore } from '@/store/products'
import * as Sentry from '@sentry/vue'

export const useUserStore = defineStore('user', () => {
  const user = ref()
  const userData = ref()
  const userConfig = ref({})
  const cerebroId = ref()
  const redirectLink = ref()
  const trackerName = ref()
  const productStore = useProductStore()
  const userAnswers = ref({})
  const emailMismatch = ref(false)
  const pageLanguage = ref('en')

  const setUserData = data => {
    userData.value = data
  }

  const setUser = async (authUser, isJustRefetch, source) => {
    if (authUser) {
      const dataToSet = {
        ...userData.value,
        userId: authUser.uid,
        firebaseUserId: authUser.uid,
        email: authUser.email,
        isEmailVerified: authUser.emailVerified,
        createdAt: authUser.metadata.createdAt,
        providerData: authUser.providerData,
      }
      const userDataPayload = await loginActions(authUser, dataToSet, isJustRefetch, source)
      setUserData({ ...dataToSet, ...userDataPayload })
      user.value = authUser
      await productStore.refetchProducts()
    } else {
      setUserData({})
      sendUserEvent()
      Sentry.setUser(null)
      user.value = null
    }
  }

  const editUserData = newUserData => {
    for (let key in newUserData) {
      userData.value[key] = newUserData[key]
    }
  }

  const updateFirebaseUserData = async () => {
    await setUserRemote(userData.value)
  }

  const getCurrentUser = () => {
    return new Promise((resolve, _) => {
      if (user.value === undefined) {
        const removeListener = watch(user, value => {
          removeListener()
          resolve(value)
        })
      } else {
        resolve(user.value)
      }
    })
  }

  const isUserPro = () => {
    if (
      userConfig.value &&
      userConfig.value.is_pro &&
      userConfig.value.is_pro.toLowerCase() === 'true'
    ) {
      // Giving an example subscription to a config made pro user
      userData.value.subscription = new Map([
        ['expiresAt', 1717940180894],
        ['gracePeriod', false],
        ['interval', 'month'],
        ['isActive', true],
        ['isAutoRenewOn', true],
        ['isFreeTrial', false],
        ['paymentProcessor', 'paddle'],
        ['price', 9.99],
        ['productId', 'pro_01hw8fyjpe9v1r33fk74cmar40'],
        ['startsAt', 1715261780894],
        ['subscriptionId', 'sub_01hxes3dpjwc0nk7965114gq5h'],
        ['subscriptionPlatform', 'web'],
      ])
      return true
    }

    return (
      !!userData.value &&
      !!userData.value.subscription &&
      userData.value.subscription.isActive != null &&
      userData.value.subscription.isActive
    )
  }

  const isSubscribedRecurring = () => {
    return (
      isUserPro() &&
      userData.value.subscription.interval != null &&
      userData.value.subscription.interval !== 'lifetime'
    )
  }

  const getSubscription = () => {
    if (isUserPro()) {
      return userData.value.subscription
    }
    return null
  }

  const setConfig = data => {
    userConfig.value = data
  }

  const getParameter = key => {
    if (userConfig.value && userConfig.value[key]) {
      return userConfig.value[key]
    }
  }

  const getIsSubsPaddle = () => {
    return userData.value.subscription?.paymentProcessor === 'paddle'
  }

  const setCerebroId = cid => {
    cerebroId.value = cid
  }

  const setRedirectLink = link => {
    localStorage.setItem('cdw_redLink', link)
    redirectLink.value = link
  }

  const getRedirectLink = () => {
    const fSR = localStorage.getItem('cdw_redLink')
    return redirectLink.value || fSR
  }

  const setTrackerName = link => {
    trackerName.value = link
  }

  const getTrackerName = () => {
    return trackerName.value
  }

  const setAnswers = answers => {
    userAnswers.value = answers
  }

  const getAnswers = () => {
    return userAnswers.value
  }

  const getEmailMismatch = () => {
    return emailMismatch.value
  }

  const setEmailMismatch = value => {
    emailMismatch.value = value
  }

  const setPageLanguage = lang => {
    pageLanguage.value = lang
  }

  const getPageLanguage = () => {
    return pageLanguage.value
  }

  return {
    user,
    userData,
    cerebroId,
    trackerName,
    redirectLink,
    setUser,
    getCurrentUser,
    editUserData,
    updateFirebaseUserData,
    isUserPro,
    isSubscribedRecurring,
    getSubscription,
    setConfig,
    getParameter,
    getIsSubsPaddle,
    setCerebroId,
    setRedirectLink,
    getRedirectLink,
    getAnswers,
    setAnswers,
    getEmailMismatch,
    setEmailMismatch,
    getTrackerName,
    setTrackerName,
    setPageLanguage,
    getPageLanguage,
  }
})
