<script setup>
import { computed } from 'vue'
const { author, text, imgName } = defineProps(['author', 'text', 'imgName'])

const imageSrc = computed(() => {
  return new URL(`../../assets/images/${imgName}.png`, import.meta.url).href
})
</script>

<template>
  <div class="container">
    <p class="label-q">”</p>
    <p class="heading-6" v-html="text"></p>
    <div class="author-box">
      <img :src="imageSrc" :alt="imgName" />
      <p class="label-sm">{{ author }}</p>
    </div>
  </div>
</template>

<style scoped>
.container {
  display: flex;
  padding: 12px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
  align-self: stretch;

  border-radius: 16px;
  border: 1px solid var(--primary-light, #c2cdff);
  background: var(--neutrals-0, #fff);
}

.label-q {
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: 48px;
  letter-spacing: -0.4px;
  color: var(--primary-base);
}

.author-box {
  display: flex;
  padding: 6px 12px;
  align-items: center;
  gap: 8px;
  border-radius: 12px;
  border: 1px solid var(--neutrals-200, #e2e4e9);
  background: var(--neutrals-100, #f6f8fa);
}

.label-sm {
  color: var(--neutrals-900);
}

.heading-6 {
  color: var(--neutrals-500);
  margin-top: -16px;
  margin-bottom: 6px;
}
</style>
