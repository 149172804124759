<script setup>
import { computed, onMounted, ref, onUnmounted } from 'vue'
import { useUserStore } from '../store/user'
import CustomSpinner from '@/components/atoms/CustomSpinner.vue'
import { sendGTagEvent } from '../helpers/gtag'
import { useRoute } from 'vue-router'
import { sendEvent } from '@/helpers/cerebro'
import { validatePurchase } from '@/helpers/paddle.js'
import Header from '@/components/molecules/Header.vue'
import DividerWithText from '@/components/molecules/DividerWithText.vue'
import { steps } from '@/constants/onboarding.js'
import { onboardings, QUIZ_ID } from '@/constants/onboarding.js'

const status = ref('checking')
const userStore = useUserStore()

const route = useRoute()
const purchaseValue = Number(route.query.pv) / 100

const productId = route.query.productId
const source = route.query.src
const price = Number(route.query.pv) / 100
const transactionId = route.query.transactionId
const currency = route.query.currency
const paymentMethod = route.query.paymentMethod
const cardType = route.query.cardType
const cardLast4 = route.query.cardLast4
const billingCycle = route.query.billingCycle
const customerCountry = route.query.customerCountry
const customerEmail = route.query.customerEmail
const subtotal = route.query.subtotal
const tax = route.query.tax
const discount = route.query.discount
const discountAmount = route.query.discountAmount
const discountApplied = route.query.discountApplied
const revenue = route.query.revenue
const error = route.query.error
const errorCode = route.query.errorCode
const errorMessage = route.query.errorMessage

const color = computed(() =>
  getComputedStyle(document.documentElement).getPropertyValue('--neutrals-300').trim()
)

const checkIfThisPurhcaseIsAlreadyTracked = () => {
  const sid = route.query.session_id
  const pidsString = localStorage.getItem('cdw_pids') || '[]'
  const pids = JSON.parse(pidsString)
  if (pids.indexOf(sid) >= 0) {
    return true
  } else {
    pids.push(sid)
    localStorage.setItem('cdw_pids', JSON.stringify(pids))
    return false
  }
}

const sendPurchaseEvents = (authUser) => {
  if (checkIfThisPurhcaseIsAlreadyTracked(authUser)) {
    return
  }

  sendGTagEvent({
    event: 'purchase',
    ecommerce: {
      transaction_id: transactionId,
      value: parseFloat(price),
      currency: 'USD',
      tax: 0.0,
      shipping: 0.0,
      items: [
        {
          item_id: 'SparkleBook',
          item_name: 'SparkleBook',
          item_category: 'One Time Payment',
          item_brand: 'SparkleBook',
          price: parseFloat(price),
          quantity: 1
        }
      ]
    }
  })

  sendEvent({
    eventName: 'purchase',
    properties: {
      product_id: productId,
      price: parseFloat(price),
      currency,
      customer_country: customerCountry,
      transaction_id: transactionId,
      customer_email: customerEmail,
      payment_method: paymentMethod,
      card_type: cardType,
      card_last_4: cardLast4,
      billing_cycle: billingCycle,
      subtotal: parseFloat(subtotal),
      tax: parseFloat(tax),
      revenue: parseFloat(revenue),
      discount_id: discount,
      discount_rate: onboardings[QUIZ_ID].discountRate,
      is_discount_applied: discountApplied,
      discount_amount: parseFloat(discountAmount),
      source: source,
      quiz_id: QUIZ_ID
    }
  })
}

const email = computed(() => userStore.userData.email)

onMounted(async () => {
  const authUser = await userStore.getCurrentUser()

  sendEvent({
    eventName: 'page_displayed',
    properties: {
      page_name: 'payment_result',
      page_number: steps.length + 1
    }
  })

  if (!route.query.success) {
    sendEvent({
      eventName: 'purchase_failed',
      properties: {
        payment_method: paymentMethod,
        source,
        error,
        error_code: errorCode,
        error_message: errorMessage
      }
    })
    sendGTagEvent({ event: 'purchase_failed' })
  }

  if (userStore.isUserPro()) {
    status.value = 'success'
    sendPurchaseEvents(authUser)
  } else {
    sendEvent({
      eventName: 'checking_transaction_in_payment_result'
    })
    const verificationResponse = await validatePurchase(transactionId)
    if (verificationResponse != null) {
      status.value = 'success'
      sendPurchaseEvents(authUser)
    } else {
      sendEvent({
        eventName: 'purchase_failed',
        properties: {
          payment_method: paymentMethod,
          source,
          error: 'validation_error',
          error_message: "Although paddle payment was successful, we couldn't verify the payment"
        }
      })
      sendGTagEvent({ event: 'purchase_failed' })
      status.value = 'cancelled'
    }
  }
})

const downloadClick = () => {
  const redicrectLink = userStore.getRedirectLink()
  sendGTagEvent({ event: 'download_app_clicked' })
  sendEvent({
    eventName: 'download_app_clicked',
    properties: { which_store: 'app_store' }
  })

  window.open(redicrectLink, '_blank')
}

onUnmounted(() => {
  sendGTagEvent({ event: 'flow_completed' })

  sendEvent({
    eventName: 'onboarding_completed',
    properties: { quiz_id: QUIZ_ID }
  })

  sendEvent({
    eventName: 'page_completed',
    properties: {
      page_name: 'payment_result',
      page_number: steps.length + 1
    }
  })
})
</script>

<template>
  <Header />
  <div class="container mt-20">
    <div v-if="status === 'checking'" class="checking-box">
      <p>Validating your payment...</p>
      <CustomSpinner></CustomSpinner>
    </div>
    <div v-if="status === 'success'" class="success-box">
      <div class="heading-6 confirmation primary-dark">Payment Successful</div>
      <img src="@/assets/images/celebrate.png" alt="celebrating" />

      <p class="heading-5">Welcome to Sparkle!</p>

      <p class="neutral-500">
        We’re so glad you’ve joined us! You now have complete access to our expanding library of
        top-selling books and the best ideas worldwide.
      </p>
      <div class="activation">
        <p class="heading-5">To activate your account:</p>
        <p class="heading-6">Log in automatically</p>
        <p class="neutral-500">
          Tap the button below to download Sparkle, and your account will be logged in
          automatically:
        </p>
        <button class="btn btn-primary" @click="downloadClick">Download</button>
        <DividerWithText :color="color" thickness="1" text="or" />
        <p class="heading-6">Log in manually</p>
        <p class="neutral-500">
          Use <span class="primary-color">{{ email }}</span> and password we have sent to its inbox.
          If it is not there, make sure to check Spam/Junk folder
        </p>
      </div>
    </div>
    <div v-if="status === 'cancelled'">
      <p class="subs-info mt-20">Payment could not be verified</p>
      <RouterLink :to="{ name: source }" class="go-pro-button mt-30">
        Go back to {{ source }}
      </RouterLink>
    </div>
  </div>
</template>

<style scoped>
.container {
  padding-bottom: 40px;
  max-height: calc(100vh - 93px);
  overflow: scroll;
}

.checking-box {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  margin-top: 50px;
}

.success-box {
  max-width: 480px;
  margin: 0 auto;
  padding: 8px;
  border-radius: 16px;
  border: 1px solid var(--primary-light);
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.neutral-500 {
  color: var(--neutrals-500);
}

img {
  max-width: 100%;
}

.confirmation {
  background: #f5af2a;
  padding: 24px;
  margin: -8px -8px 16px -8px;
}

.activation {
  background: #f5af2a33;
  border: 1px solid #f5af2a;
  width: Fill (343px) px;
  padding: 32px 12px 32px 12px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  border-radius: 12px;
}
</style>
