<script setup>
import { defineAsyncComponent } from 'vue'

const props = defineProps({
  name: {
    type: String,
    required: true
  }
})

const AsyncComponent = defineAsyncComponent(
  () => /* @vite-ignore */ import(`./icons/categories/${props.name}.vue`)
)
</script>

<template>
  <AsyncComponent />
</template>
