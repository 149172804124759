<script setup>
const props = defineProps({
  color: { type: String, default: 'var(--neutrals-100)' }, 
  thickness: { type: String, default: '2' }
})
</script>

<template>
  <div :style="`border-bottom: ${props.thickness}px solid ${props.color}`"></div>
</template>

<style scoped>
div {
  width: 100%;
}
</style>