<script setup>
import { sendVerificationEmail, isVerificationRequired } from '../helpers/verificationEmail.js'
import { watch, ref, computed, onMounted, onUnmounted } from 'vue'
import { storeToRefs } from 'pinia'
import { useUserStore } from '@/store/user'
import { useRouter } from 'vue-router'
import { auth } from '@/plugins/firebase'
const status = ref('idle')
const router = useRouter()

let interval = null

onMounted(() => {
  interval = setInterval(async () => {
    await auth.currentUser.reload()
    if (!isVerificationRequired(auth.currentUser)) {
      redirect()
    }
  }, 5000)
})

onUnmounted(() => {
  clearInterval(interval)
})

const sendVerificationHandler = async () => {
  try {
    status.value = 'loading'
    await sendVerificationEmail()
    alert('A verification e-mail is sent. Follow instructions in e-mail to continue.')
    status.value = 'sent'
    setTimeout(() => {
      status.value = 'idle'
    }, 120000)
  } catch (error) {
    status.value = 'error'
  }
}

const userStore = useUserStore()
const { user } = storeToRefs(userStore)
watch(user, (u) => {
  if (u && !isVerificationRequired(u)) {
    router.push({
      name:
        'source' in router.options.history.state ? router.options.history.state.source : 'Settings'
    })
  }
})

const verificationMessage = computed(() => {
  return status.value === 'error'
    ? 'A problem occurred while sending the verification email'
    : 'Please check your email and confirm your account'
})

const verificationButtonText = computed(() => {
  if (status.value === 'sent') {
    return 'Sent'
  } else if (status.value === 'error') {
    return 'Retry in a few seconds'
  } else {
    return 'Resend Verification Email'
  }
})
</script>

<template>
  <p class="main-text mt-20">{{ verificationMessage }}</p>

  <button
    class="email-verification mt-20"
    :class="{ 'sent-email': status != 'idle' }"
    @click="sendVerificationHandler"
    :disabled="status === 'sent'"
  >
    {{ verificationButtonText }}
  </button>

  <p class="success" v-if="status == 'sent'">*A verification e-mail is sent</p>
</template>

<style scoped></style>
