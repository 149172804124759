<script setup>
import { FAQS } from '@/constants/general'
import { ref } from 'vue'
import ArrowUp from '@/assets/icons/arrow_up_icon.svg'
import ArrowDown from '@/assets/icons/arrow_down_icon.svg'

const showFAQAnswer = ref(null)
const showAnswer = (index) => {
  if (showFAQAnswer.value === index) showFAQAnswer.value = null
  else showFAQAnswer.value = index
}
</script>

<template>
  <div class="container">
    <p class="heading-5">Frequently Asked Questions</p>
    <p class="p-md mt-12">Get fast answers about general questions</p>
    <div class="qa-container" v-for="(qa, index) in FAQS" @click="showAnswer(index)">
      <div class="question">
        <p class="label-md">{{ qa.question }}</p>
        <ArrowUp v-if="showFAQAnswer === index" class="arrow" />
        <ArrowDown v-else class="arrow" />
      </div>
      <transition name="slide">
        <div v-if="showFAQAnswer === index" class="answer p-sm">
          <p v-html="qa.answer"></p>
        </div>
      </transition>
    </div>
  </div>
</template>

<style scoped>
.container {
  background: var(--neutrals-100);
  border: 1px solid var(--neutrals-200);
  padding: 32px 20px;
  border-radius: 12px;
  text-align: center;
  padding-bottom: 0;
  width: 100%;
}

.qa-container {
  cursor: pointer;
  border-bottom: 1px solid var(--neutrals-200);
  padding: 24px 0px;
  text-align: left;
}

.question {
  display: flex;
  justify-content: space-between;
}

.qa-container:last-child {
  border-bottom: none;
}

.p-md {
  margin-bottom: 24px;
}

.answer {
  padding-top: 16px;
}
</style>
