import { isStaging } from '@/helpers/environment'

export const prices = isStaging
  ? [
    { quantity: 1, priceId: 'pri_01j7bjjrhrqm17q9v6wdh6zxz7' },
    { quantity: 1, priceId: 'pri_01j7dmjvaehc9b9fsdvqcw5dap' },
    { quantity: 1, priceId: 'pri_01j6csvtcn8dg91r5abjcwbr61' },
  ]
  : [
    { quantity: 1, priceId: 'pri_01jdptfzpj2f9e1pck4ww3a2w8' },
    { quantity: 1, priceId: 'pri_01jdptj38adev76780hgbqevsr' },
  ]

export const discount = isStaging ? 'dsc_01jc5e8ck055ygcbc2g4zm17ad' : 'dsc_01jdpv00zr8nd407wa3wvph019'
