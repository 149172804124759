<script setup>
import { computed } from 'vue'

const { img, name, isSelected } = defineProps(['img', 'name', 'isSelected'])
const imgSrc = computed(() => {
  return new URL(`../../assets/images/${img}.png`, import.meta.url).href
})
</script>

<template>
  <div class="container">
    <div :class="`img-container ${isSelected ? 'selected' : ''}`">
      <img :src="imgSrc" :alt="name" />
    </div>
    <p class="p-sm">{{ name }}</p>
  </div>
</template>

<style scoped>
.container {
  display: inline-block;
  padding: 10px;
}

.img-container {
  width: 100px;
  height: 100px;
  border: 1px solid var(--neutrals-200);
  border-radius: 12px;
  background-color: #fff;
}

.img-container:hover {
  background-color: var(--primary-lighter);
}

.img-container.selected {
  background-color: var(--primary-lighter);
  border: 1px solid var(--primary-base);
}

img {
  width: 100%;
}
</style>
