<script setup></script>
<template>
  <div class="component-container">
    <div class="testimonial-container mt-16">
      <p class="heading-4 primary-color">Thousands of users</p>
      <p class="p-md">already use Sparkle App</p>
      <div class="quote">
        <img src="@/assets/icons/quote-icon.png" width="17px" alt="quote-icon" />
        <p class="heading-6">
          "Sparkle is a tool for acquiring and absorbing as much information as possible"
        </p>
        <div class="person-tag mt-12">
          <img src="@/assets/images/person.png" alt="person" />
          <span class="label-sm">Fig Nelson</span>
        </div>
      </div>
      <div><img src="@/assets/icons/five_stars.png" alt="five-stars" width="120px" /></div>
      <div class="other-people p-md">
        <img src="@/assets/images/other_people.png" alt="person" width="92px" />
        from users
      </div>
    </div>
    <div class="btn btn-primary label-md cnt-btn" @click="$emit('cta')">Continue</div>
  </div>
</template>

<style scoped>
.testimonial-container {
  display: flex;
  flex-direction: column;
  padding: 24px 8px;
  gap: 12px;
  background-color: var(--primary-lighter);
  border: 1px solid var(--primary-light);
  border-radius: 16px;
}

.quote {
  background-color: #fff;
  border: 1px solid var(--primary-light);
  padding: 16px 12px 24px 12px;
  gap: 12px;
  border-radius: 16px;
}

.person-tag {
  display: inline-flex;
  gap: 8px;
  align-items: center;
  padding: 6px 12px;
  gap: 8px;
  border-radius: 12px;
  border: 1px solid var(--neutrals-200);
  background: var(--neutrals-100);
}

.person-tag > img {
  width: 32px;
  height: 32px;
}

.other-people {
  display: flex;
  gap: 5px;
  justify-content: center;
  align-items: center;
  color: #737373;
}
</style>
