<script setup>
import { ref } from 'vue'
const emit = defineEmits()

const timerText = ref('10:00')

let totalSeconds = 600

const discountEnd = ref(false)

const endDiscount = () => {
  timerText.value = '00:00'
  discountEnd.value = true
  emit('discount-ended')
}

const timer = setInterval(() => {
  const minutes = Math.floor(totalSeconds / 60)
  let seconds = totalSeconds % 60

  if (seconds < 10) {
    seconds = `0${seconds}`
  }

  timerText.value = `${minutes}:${seconds}`

  if (totalSeconds === 0) {
    clearInterval(timer)
    endDiscount()
  } else {
    totalSeconds--
  }
}, 1000)
</script>

<template>
  <div :class="`card timer-card ${discountEnd ? 'hidden' : ''}`">
    <div>
      <p class="label-xs">60% discount reserved for:</p>
      <p class="label-md primary-dark">{{ timerText }}</p>
    </div>
    <div class="flex-center">
      <button class="btn btn-xs btn-primary label-xs" @click="$emit('discount-continue-clicked')">
        Continue
      </button>
    </div>
  </div>
</template>

<style scoped>
.btn-primary {
  padding: 6px 10px;
  border-radius: 8px;
}
</style>
