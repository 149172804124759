<template>
  <span class="loader"></span>
</template>

<style scoped>
.loader {
  width: 18px;
  height: 18px;
  border: 4px solid #fff;
  border-bottom-color: var(--beige-dark);
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
  color: var(--primary-Base, #3d2df2);
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>
