<script setup>
import { onMounted, ref } from 'vue'
import lottie from 'lottie-web'
import animationData from '@/assets/animations/flow_up.json'

const animationContainer = ref(null)

onMounted(() => {
  const animation = lottie.loadAnimation({
    container: animationContainer.value,
    renderer: 'svg',
    autoplay: true,
    loop: false,
    animationData
  })
})
</script>

<template>
  <div class="container">
    <div class="plan-div">
      <div class="graph">
        <div ref="animationContainer"></div>
        <div class="graph-overlay">
          <div class="start">
            <div class="btn btn-xs label-xs">Start here</div>
            <div class="sub-h-xs">NOW</div>
          </div>
          <div class="end">
            <div class="btn btn-xs label-xs">28 Books</div>
            <div class="sub-h-xs">1 MONTH LATER</div>
          </div>
        </div>
      </div>
      <p class="heading-4 primary-color">Your personalized growth plan is prepared.</p>
      <p>We’ve crafted a self-growth plan based on your responses.</p>
    </div>
    <div class="stores">
      <img src="@/assets/images/google_play_badge.png" alt="play-store-badge" />
      <img src="@/assets/images/app_store_badge.png" alt="app-store-badge" />
    </div>
    <p class="p-sm">In order to use the app you will need an iPhone or Android smartphone</p>
    <button class="btn btn-primary label-md cnt-btn" @click="$emit('cta')">Continue</button>
  </div>
</template>

<style scoped>
.container {
  max-width: 428px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.stores {
  display: flex;
  gap: 6px;
  justify-content: center;
}

.stores > img {
  width: 100px;
}

.plan-div {
  padding: 24px 16px;
  border-radius: 16px;
  background-color: #fff;
  border: 1px solid var(--primary-light);
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.graph {
  position: relative;
}

.graph-overlay {
  position: absolute;
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  bottom: 5px;
  left: 10px;
  align-items: flex-end;
}

.start,
.end {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.start {
  height: 53%;
  color: var(--primary-base);
  margin-left: -8px;
}

.end {
  height: 95%;
  color: #f5af2a;
  width: 100px;
  margin-right: -4px;
}

.btn {
  border-radius: 12px;
  padding: 4px 10px;
}
</style>
