import { useRouter } from 'vue-router'

export const getPage = () => {
  const router = useRouter()
  if (router) {
    return router.currentRoute.value.name
  } else {
    return ''
  }
}
