<script setup>
import { computed, onMounted, ref } from 'vue'
import { useRouter } from 'vue-router'

import Header from '@/components/molecules/Header.vue'
import SurveyHeader from '@/components/molecules/SurveyHeader.vue'
import ProgressIndicator from '@/components/molecules/ProgressIndicator.vue'
import { createUserAndLogin } from '@/helpers/authUser'
import { useUserStore } from '@/store/user'
import { setUserData } from '@/helpers/userManager'

import { sendEvent } from '@/helpers/cerebro'
import { sendGTagEvent } from '../helpers/gtag'
import { steps } from '@/constants/onboarding.js'

const router = useRouter()

const isSurveyCompleted = ref(false)

const userStore = useUserStore()

const summary = computed(() => {
  const sections = {}
  let introSteps = 0
  let surveyStarted = false
  steps.forEach((step) => {
    if (step.section) {
      surveyStarted = true
      if (sections[step.section]) {
        sections[step.section]++
      } else {
        sections[step.section] = 1
      }
    } else if (!surveyStarted) {
      introSteps++
    }
  })
  return { sections, introSteps }
})

const currentStep = ref(0)
const currentSectionText = computed(() => steps[currentStep.value].section)
const isIntroStep = computed(() => !currentSectionText.value)
const responsePage = ref({ show: false })
const isLoading = ref(false)

const answers = []

onMounted(() => {
  sendPageDisplayedEvents()
})

const onSelection = (optionIndex) => {
  answers[currentStep.value] = optionIndex
  const currentStepObj = steps[currentStep.value]

  const { key, value } = getEachAnswer(answers[currentStep.value], currentStep.value)
  sendGTagEvent({
    event: 'page_completed',
    step_number: currentStep.value,
    page_name: key
  })
  sendEvent({
    eventName: 'page_completed',
    properties: {
      page_name: key,
      questions: currentStepObj.props?.title,
      answer: value,
      input_format: currentStepObj.component,
      page_number: currentStep.value,
      page_language: userStore.getPageLanguage()
    }
  })

  if (currentStepObj.responsePage) {
    responsePage.value = {
      show: true,
      ...currentStepObj.responsePage
    }
  } else if (currentStepObj.dynamicResponse) {
    responsePage.value = {
      show: true,
      ...currentStepObj.props.options[optionIndex].responsePage
    }
  } else {
    goNextStep()
  }
}

const goNextStep = async () => {
  if (responsePage.value.show) {
    responsePage.value.show = false
  }
  if (currentStep.value === Math.floor(steps.length / 2)) {
    sendGTagEvent({
      event: 'flow_half_completed'
    })
  }
  if (currentStep.value === steps.length - 1) {
    isSurveyCompleted.value = true
    await setUserData({ surveyAnswers: formatAnswers() })
    router.push('/payment')
  } else {
    currentStep.value++
    sendPageDisplayedEvents()
  }
}

const sendPageDisplayedEvents = () => {
  sendGTagEvent({
    event: 'page_displayed',
    page_name: steps[currentStep.value].name,
    step_number: currentStep.value
  })
  sendEvent({
    eventName: 'page_displayed',
    properties: {
      page_name: steps[currentStep.value].name,
      page_number: currentStep.value,
      questions: steps[currentStep.value].props?.title,
      page_language: userStore.getPageLanguage()
    }
  })
}

const formatAnswers = () => {
  const formattedAnswers = {}
  answers.forEach((answer, index) => {
    const { key, value } = getEachAnswer(answer, index)
    if (key !== null) {
      formattedAnswers[key] = value
    }
  })
  return formattedAnswers
}

const getEachAnswer = (answer, index) => {
  if (answer !== undefined) {
    const step = steps[index]
    const key = step.name || String(index)
    let value
    if (typeof answer === 'number') {
      const stepAnswer = step.props.options[answer]
      value = getValueFromSelectedOption(stepAnswer, answer)
    } else {
      value = answer.map((i) => getValueFromSelectedOption(step.props.options[i], i))
    }
    return { key, value }
  } else {
    return { key: null, value: null }
  }
}

const getValueFromSelectedOption = (stepAnswer, answer) => {
  if (typeof stepAnswer === 'object') {
    return stepAnswer.id || stepAnswer.text || stepAnswer.name || 'option-' + answer
  } else if (typeof stepAnswer === 'string') {
    return stepAnswer
  } else {
    return answer
  }
}

const goPreviousStep = () => {
  if (responsePage.value.show) {
    responsePage.value.show = false
  } else {
    currentStep.value--
  }
  sendPageDisplayedEvents()
}

const handleEmailInput = async ({ email, emailPermission }) => {
  isLoading.value = true
  const resp = await createUserAndLogin(email, emailPermission, userStore.getTrackerName())
  if (resp.redirectLink) userStore.setRedirectLink(resp.redirectLink)
  isLoading.value = false
  goNextStep()
}
</script>

<template>
  <Header v-if="isIntroStep" />
  <SurveyHeader @back="goPreviousStep" v-else />
  <div class="main-container">
    <Transition name="fade" mode="out-in">
      <component
        :is="steps[currentStep].component"
        v-if="isIntroStep"
        v-bind="steps[currentStep].props"
        :key="'intro' + currentStep"
        @selection="onSelection"
        @cta="goNextStep"
        @emailInput="handleEmailInput"
        :isLoading="isLoading"
      />
    </Transition>
    <div v-if="!isIntroStep" class="mt-24 survey-container">
      <div class="mb-40">
        <Transition name="fade">
          <ProgressIndicator
            :current-section-text="currentSectionText"
            :summary="summary"
            :currentStep="currentStep"
            :isCurrentStepComplete="responsePage.show || isSurveyCompleted"
          />
        </Transition>
      </div>
      <Transition name="fade" mode="out-in">
        <component
          :is="responsePage.show ? responsePage.component : steps[currentStep].component"
          v-bind="responsePage.show ? responsePage.props : steps[currentStep].props"
          :key="`progress${currentStep}`"
          :selectedIndex="answers[currentStep]"
          @selection="onSelection"
          @cta="goNextStep"
          @emailInput="handleEmailInput"
          :isLoading="isLoading"
        />
      </Transition>
    </div>
    <div class="main-bottom"></div>
    <div class="fade-bottom"></div>
  </div>
</template>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.25s;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.main-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  padding: 24px 24px 64px 24px;
  height: calc(100vh - 64px);
  overflow-y: scroll;
}

.main-container > * {
  max-width: 428px;
}

.survey-container {
  width: 100%;
  max-width: 428px;
}

.main-bottom {
  display: none;
}

@media screen and (max-width: 850px) {
  .main-bottom {
    width: 100%;
    height: 90px;
    display: block;
  }

  .fade-bottom {
    position: fixed;
    width: 100vw;
    height: 31px;
    bottom: 0;
    background: linear-gradient(rgba(255, 248, 244, 0.59), rgb(255, 248, 244));
  }

  .main-container {
    padding: 16px;
    min-height: calc(100vh - 64px);
    height: 100%;
  }

  .mb-40 {
    margin-bottom: 20px;
  }

  .mt-24 {
    margin-top: 8px;
  }
}
</style>

<script>
import { defineComponent } from 'vue'

import AgeSelection from '@/components/organisms/AgeSelection.vue'
import TestimonialSection from '@/components/organisms/TestimonialSection.vue'
import SelectOneList from '@/components/organisms/SelectOneList.vue'
import SelectOneRow from '@/components/organisms/SelectOneRow.vue'
import InformationCard from '@/components/organisms/InformationCard.vue'
import MultiSelectCard from '@/components/organisms/MultiSelectCard.vue'
import MultiSelectWithImagesCard from '@/components/organisms/MultiSelectWithImagesCard.vue'
import ImageLike from '@/components/organisms/ImageLike.vue'
import MultiSelectList from '@/components/organisms/MultiSelectList.vue'
import SocialProof from '@/components/organisms/SocialProof.vue'
import SocialProof2 from '@/components/organisms/SocialProof2.vue'
import LoadingPopUps from '@/components/organisms/LoadingPopUps.vue'
import QuoteCard from '@/components/atoms/QuoteCard.vue'
import EmailForm from '@/components/organisms/EmailForm.vue'
import PlanPage from '@/components/organisms/PlanPage.vue'

export default defineComponent({
  components: {
    AgeSelection,
    TestimonialSection,
    SelectOneList,
    SelectOneRow,
    InformationCard,
    MultiSelectCard,
    MultiSelectWithImagesCard,
    ImageLike,
    MultiSelectList,
    SocialProof,
    SocialProof2,
    LoadingPopUps,
    QuoteCard,
    EmailForm,
    PlanPage
  }
})
</script>
