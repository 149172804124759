<script setup>
import AgeSelectionBox from '../molecules/AgeSelectionBox.vue'

import { sendGTagEvent } from '@/helpers/gtag'
const { title, options } = defineProps(['title', 'options'])

const emit = defineEmits(['selection'])

function sendAgeSelectionEvent(index) {
  sendGTagEvent({ event: 'age_collected', age_range: options[index] })
  emit('selection', index)
}
</script>

<template>
  <div class="age-container">
    <p class="heading-5">{{ title }}</p>
    <p class="sub-h-xs primary-color">3 MINUTE QUIZ</p>
    <div class="age-selection-container">
      <AgeSelectionBox
        v-for="(ageGroup, index) in options"
        :key="ageGroup"
        :age-segment="ageGroup"
        @click="sendAgeSelectionEvent(index)"
      />
      <p class="p-xs lighter">
        By continuing, you agree to our
        <a
          class="action-text"
          href="/policies/Terms.html"
          style="text-decoration: underline"
          target="_blank"
          >Terms & Conditions</a
        >
        and
        <a
          class="action-text"
          href="/policies/Privacy.html"
          style="text-decoration: underline"
          target="_blank"
        >
          Privacy Policy</a
        >
      </p>
    </div>
  </div>
</template>

<style scoped>
.age-container {
  padding: 16px;
  max-width: 396px;
}

.sub-h-xs {
  margin-top: 4px;
}

.age-selection-container {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  margin: 16px;
}

.lighter {
  opacity: 0.5;
}
</style>
