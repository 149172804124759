<script setup>
import Divider from '@/components/atoms/Divider.vue'

const props = defineProps({
  color: { type: String, default: 'var(--neutrals-300)' },
  thickness: { type: String },
  text: String
})
</script>

<template>
  <div class="divider-with-text">
    <Divider :color="props.color" :thickness="props.thickness" />
    <div class="">{{ props.text }}</div>
    <Divider :color="props.color" :thickness="props.thickness" />
  </div>
</template>

<style scoped>
.divider-with-text {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
</style>
