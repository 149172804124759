<template>
  <Teleport to="body">
    <div v-if="isVisible" class="modal-overlay" @click.self="close">
      <div class="modal-content">
        <button v-if="closeIcon" class="modal-close" @click="close">×</button>
        <slot></slot>
      </div>
    </div>
  </Teleport>
</template>

<script setup>
import { ref, watch } from 'vue'

const props = defineProps({
  isVisible: {
    type: Boolean,
    default: false
  },
  closeIcon: {
    type: Boolean,
    default: true
  }
})

const emit = defineEmits(['close'])

const close = () => {
  emit('close')
}

watch(
  () => props.isVisible,
  (newVal) => {
    if (newVal) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = ''
    }
  }
)
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(31, 32, 42, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  padding: 80px 8px;
}

.modal-content {
  background: white;
  border-radius: 20px;
  position: relative;
  max-height: min(100vh, 100%);
  max-width: 100vw;
  overflow-y: scroll;
}

.modal-close {
  position: absolute;
  top: 26px;
  right: 26px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  z-index: 1000;
}

@media screen and (max-width: 1200px) {
  .modal-overlay {
    padding: 50px 8px;
  }

  .modal-close {
    /* For paywall */
    top: 21px;
    right: 30px;
  }
}
</style>
