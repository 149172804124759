import { auth } from './plugins/firebase'
import './assets/css/main.css'
import * as Sentry from "@sentry/vue";
import router from './router'

// Components
import App from './App.vue'

// Composables
import { createApp } from 'vue'

// Plugins
import { registerPlugins } from '@/plugins'

const app = createApp(App)

Sentry.init({
  app,
  dsn: "https://9b10d6f5fd04262184ffe703812f82ea@o4508415214223360.ingest.us.sentry.io/4508415217041408",
  integrations: [
    Sentry.browserTracingIntegration({ router }),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    'localhost',
    /^https:\/\/sparklebook\.app\/(?:user|auth|paddle|payment)/,
    /^https:\/\/preprod.sparklebook\.app\/(?:user|auth|paddle|payment)/,
    /^https:\/\/staging.sparklebook\.app\/(?:user|auth|paddle|payment)/,
  ],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

registerPlugins(app)

app.mount('#app')
