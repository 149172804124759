<script setup>
import { ref, computed } from 'vue'
import ButtonWithCheck from '../atoms/ButtonWithCheck.vue'
import AsyncComponent from '@/components/atoms/AsyncComponent.vue'

const { title, subtitle, options, selectedIndexProp, maxSelections } = defineProps([
  'title',
  'subtitle',
  'options',
  'selectedIndex',
  'maxSelections'
])

const maxLimitReached = ref(false)

const selectedIndexes = ref(
  selectedIndexProp
    ? selectedIndexProp.reduce((acc, curr) => {
        acc[curr] = true
        return acc
      }, {})
    : {}
)

const isAnySelected = computed(() => {
  return Object.values(selectedIndexes.value).some((value) => value)
})

const selectIndex = (index) => {
  if (maxLimitReached.value && !selectedIndexes.value[index]) return
  selectedIndexes.value[index] = !selectedIndexes.value[index]
  if (
    maxSelections &&
    Object.values(selectedIndexes.value).filter((item) => item).length == maxSelections
  ) {
    maxLimitReached.value = true
  } else {
    maxLimitReached.value = false
  }
}
</script>

<template>
  <div class="container">
    <p class="heading-5">{{ title }}</p>
    <ButtonWith2Check></ButtonWith2Check>
    <p class="p-md">{{ subtitle }}</p>
    <div class="options mt-24">
      <ButtonWithCheck
        :class="`mt-12 ${selectedIndexes[index] ? 'selected' : ''}`"
        :disabled="maxLimitReached && !selectedIndexes[index]"
        v-for="(option, index) in options"
        :key="(option.name || option) + (maxLimitReached && !selectedIndexes[index])"
        @click="
          () => {
            selectIndex(index)
          }
        "
      >
        <div class="row">
          <AsyncComponent :name="option.iconComponentName"></AsyncComponent>
          {{ typeof option === 'string' ? option : option.name }}
        </div>
      </ButtonWithCheck>
    </div>
    <button
      class="btn btn-primary label-md cnt-btn continue-btn"
      :disabled="isAnySelected == false"
      @click="$emit('selection', Object.keys(selectedIndexes))"
    >
      Continue
    </button>
  </div>
</template>

<style scoped>
.container {
  width: 100%;
  max-width: 428px;
  max-height: 100%;
  max-height: calc(100vh - 230px);
  display: flex;
  flex-direction: column;
  padding: 16px;
}

.options {
  flex-grow: 1;
  height: 100%;
  overflow: scroll;
  padding: 16px;
}

.continue-btn {
  position: fixed;
  bottom: 60px;
  left: 50%;
  transform: translateX(-50%);
  cursor: pointer;
  max-width: 428px;
  width: calc(100% - 32px);
}

.p-md {
  color: var(--neutrals-500);
  margin-top: 8px;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}
</style>
