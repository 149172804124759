<script setup>
import { ref } from 'vue'

import Header from '@/components/molecules/Header.vue'
import SurveyHeader from '@/components/molecules/SurveyHeader.vue'
import AgeSelection from '@/components/organisms/AgeSelection.vue'
import TestimonialSection from '@/components/organisms/TestimonialSection.vue'
import SelectOneList from '@/components/organisms/SelectOneList.vue'
import SelectOneRow from '@/components/organisms/SelectOneRow.vue'
import ProgressIndicator from '@/components/molecules/ProgressIndicator.vue'
import InformationCard from '@/components/organisms/InformationCard.vue'
import MultiSelectCard from '@/components/organisms/MultiSelectCard.vue'
import MultiSelectWithImagesCard from '@/components/organisms/MultiSelectWithImagesCard.vue'
import ImageLike from '@/components/organisms/ImageLike.vue'
import SocialProof from '@/components/organisms/SocialProof.vue'
import MultiSelectList from '@/components/organisms/MultiSelectList.vue'
import EmailForm from '@/components/organisms/EmailForm.vue'
import PlanPage from '@/components/organisms/PlanPage.vue'
import { createUserAndLogin } from '@/helpers/authUser'

const sectionCount = ref(3)
const currentPercentage = ref(0)
const currentSectionText = ref('Profile')

const genderOptions = ['👱‍♂️ Male', '👱‍♀️ Female', '🧑‍🦳 Other']
const detailOptions = ['🔭 Big-picture', '🔬 Detail-oriented']
const knowWhatYouWantOptions = [
  {
    text: '👎',
    feedback: {
      title: 'We hear you!',
      text: 'Your selected growth points will guide us in tailoring the most fitting plan for you.'
    }
  },
  {
    text: '🤷‍♂️',
    feedback: {
      title: 'I understand!',
      text: 'Pinpointing your desires can be challenging, but a personalized growth plan will assist in clarifying, prioritizing, and smoothly achieving your goals.'
    }
  },
  {
    text: '👍',
    feedback: {
      title: 'Were you aware people spend more than 4 hours on their phones every day?',
      text: "If you put just 15 mins aside daily for using Blinkist you'll grow your knowledge and reach your goals."
    }
  }
]

const personalGrowthAreas = [
  'Emotions',
  'Motivation',
  'Nutrition',
  'Habits',
  'Love & relationships',
  'Mindset',
  'Exercise',
  'Empathy',
  'Self-care',
  'Self-confidence',
  'Personal Finance',
  'Creativity'
]

const idols = [
  { img: 'jobs', name: 'Steve Jobs' },
  { img: 'branson', name: 'Richard Branson' },
  { img: 'james', name: 'LeBron James' },
  { img: 'oprah', name: 'Oprah Winfrey' },
  { img: 'watson', name: 'Emma Watson' },
  { img: 'williams', name: 'Serena Williams' },
  { img: 'bezos', name: 'Jeff Bezos' },
  { img: 'hart', name: 'Kevin Hart' },
  { img: 'brown', name: 'Brené Brown' }
]

const doubtYourselfOptions = [{ text: '👎' }, { text: '🤷‍♂️' }, { text: '👍' }]

// const leaderOptions = ['🚀 A leader', '👣 A Follower', '😎 A little bit of both']
const leaderOptions = [
  {
    text: '🚀 A leader',
    feedback: {
      title: 'Leader!',
      text: 'The role of a leader can be quite demanding. By spending just 15 minutes a day, you’ll efficiently learn how to enhance your leadership skills.'
    }
  },
  {
    text: '👣 A Follower',
    feedback: {
      title: 'Self-awareness is crucial for unlocking your potential. 🙏',
      text: 'A follower can be an excellent team player and independent contributor. However, you can transform this by starting to explore yourself to uncover your strengths and areas for growth.'
    }
  },
  {
    text: '😎 A little bit of both',
    feedback: {
      title: 'Say “no” to traditional labels or roles. 😎',
      text: 'We’ll help you enhance both leadership and team player skills with our library of over 1200 titles.'
    }
  }
]

const multiSelectOptions = [
  '🚀 Getting a promotion',
  '💼 Starting a business',
  '📈 Boosting leadership skills',
  '💡 Enhancing productivity',
  '💑 Improving relationships',
  '😎 Building confidence',
  '🤯 Managing stress better',
  '💪🏻 Improving health and fitness',
  '🔥 Enriching your sex life',
  '📚 Developing better habits'
]

const timeGoalOptions = [
  { text: '🤙 Easy', subtext: '5 min/day' },
  { text: '👌 Common', subtext: '10 min/day' },
  { text: '🤘 Serious', subtext: '15 min/day' },
  { text: '💪 Intensive', subtext: '20+ min/day' }
]
import { useUserStore } from '@/store/user'
const userStore = useUserStore()

const handleEmailInput = async ({ email, emailPermission }) => {
  const resp = await createUserAndLogin(email, emailPermission, userStore.getTrackerName())
  if (resp.redicrectLink) userStore.setRedirectLink(resp.redicrectLink)
  goNextStep()
}
</script>

<template>
  <Header v-if="false" />
  <SurveyHeader />
  <div class="main-container">
    <PlanPage v-if="false" />
    <EmailForm v-if="true" @emailInput="handleEmailInput" />
    <SocialProof v-if="false" />
    <SelectOneList
      title="Set your goal for timing"
      text="Choose how much time you’ll dedicate to self-development each day."
      :options="timeGoalOptions"
      v-if="false"
    />
    <MultiSelectList
      title="Do you have a particular reason for pursuing self-growth?"
      :options="multiSelectOptions"
      v-if="false"
    />
    <ImageLike
      v-if="false"
      img="the_art_of_work"
      title="Select the books that pique your interest."
    />
    <AgeSelection v-if="false" />
    <TestimonialSection v-if="false" />
    <div class="mt-24 survey-container">
      <div class="mb-40">
        <ProgressIndicator
          v-if="false"
          :section-count="sectionCount"
          :current-percentage="currentPercentage"
          :current-section-text="currentSectionText"
        />
      </div>
      <SelectOneList title="Select your gender" :options="genderOptions" v-if="false" />
      <InformationCard
        v-if="false"
        imgName="book_reading"
        title="Yay, glad you are here!"
        text="We’ll help you with self-growth. First, let’s dive into your personality and tailor your personal plan."
      />
      <MultiSelectCard
        title="In which areas do you want to grow?"
        text="The choice won’t limit your experience"
        :options="personalGrowthAreas"
        v-if="false"
      />
      <InformationCard
        title="Understand big ideas in minutes instead of hours!"
        text="Cover any book in just 15 minutes with our app. This way, you'll make the most of your time putting your new knowledge into action."
        imgName="understand_big_ideas"
        v-if="false"
      />
      <MultiSelectWithImagesCard
        title="Whose life principles, success, and personality inspire you the most?"
        :options="idols"
        v-if="false"
      />
      <SelectOneList
        title="Are you a big-picture or detail-oriented person?"
        :options="detailOptions"
        v-if="false"
      />
      <SelectOneRow
        title="You always know what you want, agree?"
        :options="knowWhatYouWantOptions"
        isWithFeedback="true"
        v-if="false"
      />
      <SelectOneRow
        title="I tend to doubt myself and my skills if I make a mistake"
        :options="doubtYourselfOptions"
        v-if="false"
      />

      <SelectOneList
        title="Which of the following describes you better?"
        :options="leaderOptions"
        isWithFeedback="true"
        v-if="false"
      />
    </div>
  </div>
</template>

<style scoped>
.main-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  padding-bottom: 64px;
  height: 100%;
  height: calc(100vh - 64px);
}

.survey-container {
  max-width: 428px;
  padding-left: 16px;
  padding-right: 16px;
}
</style>
