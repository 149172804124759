import { auth } from '@/plugins/firebase'
import axios from 'axios'
import { BACKEND_BASE_URL } from '../constants/general.js'

export const getIdToken = async () => {
  const user = auth.currentUser
  if (user !== null) {
    return user.getIdToken()
  } else {
    return undefined
  }
}

export const requestWithIdToken = async (params) => {
  params.url = BACKEND_BASE_URL + params.path
  delete params.path
  const axiosConfig = {
    ...params,
  }
  const idToken = await getIdToken()
  if (idToken) {
    if (axiosConfig.headers) {
      axiosConfig.headers.authorization = idToken
    } else {
      axiosConfig.headers = { authorization: idToken }
    }
    return await axios(axiosConfig)
  } else {
    return new Promise((_, reject) => reject(401))
  }
}