<script setup>
import { computed } from 'vue';

const props = defineProps({
  progress: Number,
  imgName: String,
});

const imageSrc = computed(() => {
  return new URL(`../../assets/images/${props.imgName}.png`, import.meta.url).href
})

const radius = 78; 
const circumference = 2 * Math.PI * radius;

const strokeDash = computed(() => {
  const strokeLength = (props.progress / 100) * circumference;
  return `${strokeLength},${circumference - strokeLength}`;
});

const strokeDashOffset = computed(() => {
  return circumference / 4;  
});

</script>

<template>
  <div class="progress-circle">
    <svg xmlns="http://www.w3.org/2000/svg" width="160" height="160" class="circular-chart" viewBox="0 0 160 160" fill="none">
      <path class="circle-bg" d="M158.073 80.0078C158.073 90.2604 156.053 100.413 152.13 109.885C148.206 119.357 142.456 127.964 135.206 135.213C127.956 142.463 119.35 148.214 109.877 152.137C100.405 156.061 90.2531 158.08 80.0005 158.08C69.7479 158.08 59.5957 156.061 50.1235 152.137C40.6513 148.214 32.0447 142.463 24.795 135.213C17.5454 127.964 11.7946 119.357 7.8711 109.885C3.9476 100.413 1.9282 90.2604 1.9282 80.0078C1.9282 69.7552 3.9476 59.603 7.8711 50.1308C11.7946 40.6587 17.5454 32.052 24.795 24.8024C32.0447 17.5527 40.6514 11.8019 50.1235 7.87842C59.5957 3.95492 69.7479 1.93552 80.0005 1.93552C90.2531 1.93552 100.405 3.95493 109.877 7.87843C119.35 11.8019 127.956 17.5527 135.206 24.8024C142.456 32.0521 148.206 40.6587 152.13 50.1309C156.053 59.603 158.073 69.7552 158.073 80.0078L158.073 80.0078Z" stroke="#E2E4E9" />
      <path class="circle" d="M158.073 80.0078C158.073 90.2604 156.053 100.413 152.13 109.885C148.206 119.357 142.456 127.964 135.206 135.213C127.956 142.463 119.35 148.214 109.877 152.137C100.405 156.061 90.2531 158.08 80.0005 158.08C69.7479 158.08 59.5957 156.061 50.1235 152.137C40.6513 148.214 32.0447 142.463 24.795 135.213C17.5454 127.964 11.7946 119.357 7.8711 109.885C3.9476 100.413 1.9282 90.2604 1.9282 80.0078C1.9282 69.7552 3.9476 59.603 7.8711 50.1308C11.7946 40.6587 17.5454 32.052 24.795 24.8024C32.0447 17.5527 40.6514 11.8019 50.1235 7.87842C59.5957 3.95492 69.7479 1.93552 80.0005 1.93552C90.2531 1.93552 100.405 3.95493 109.877 7.87843C119.35 11.8019 127.956 17.5527 135.206 24.8024C142.456 32.0521 148.206 40.6587 152.13 50.1309C156.053 59.603 158.073 69.7552 158.073 80.0078L158.073 80.0078Z" 
      :style="{ 'stroke-dasharray': strokeDash, 'stroke-dashoffset': strokeDashOffset }" />
    </svg>

    <div class="img-container">
      <img :src="imageSrc" :alt="props.imgName" />
      <p v-if="props.progress < 100"> %{{ props.progress }}... </p>
      <p v-else> %{{ props.progress }} </p>
    </div>
  </div>
</template>

<style scoped>
.progress-circle {
  position: relative;
  width: 160px;  
  height: 160px;
  margin: 10px auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.circular-chart {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.img-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  color: var(--primary-base);
  gap: 8px;
}

.circle-bg, .circle {
  stroke-linecap: round;
  stroke-width: 3.85542;
}

.circle {
  stroke: var(--primary-base);
  stroke-width: 2.8;
  transition: stroke-dasharray 0.6s ease-out;
}

.percentage {
  fill: var(--neutrals-200);
  font-size: 0.35em;
  text-anchor: middle;
}
</style>
