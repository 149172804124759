<script setup></script>
<template>
  <div class="component-container">
    <div class="testimonial-container mt-40">
      <img src="@/assets/images/people_of_world.png" class="info-img" alt="people-of-world" />
      <p class="heading-4 primary-color">Join over thousands of people</p>
      <p class="p-md">
        Become a part of growing worldwide community and achieve your goals with us
      </p>
      <div><img src="@/assets/icons/five_stars.png" alt="five-stars" width="120px" /></div>
      <div class="other-people p-md">
        <img src="@/assets/images/other_people.png" alt="person" width="92px" />
        from users
      </div>
    </div>
    <div class="btn btn-primary label-md cnt-btn" @click="$emit('cta')">Continue</div>
  </div>
</template>

<style scoped>
.testimonial-container {
  background-color: var(--primary-lighter);
  padding: 24px 8px;
  gap: 12px;
  border-radius: 16px;
  border: 1px solid var(--primary-light);
  display: flex;
  flex-direction: column;
  width: 100%;
}

.info-img {
  width: 100%;
}

.other-people {
  display: flex;
  gap: 5px;
  justify-content: center;
  align-items: center;
  color: #737373;
}
</style>
