import { sendEmailVerification } from 'firebase/auth'
import { auth } from '@/plugins/firebase'

export const sendVerificationEmail = async () => {
  return await sendEmailVerification(auth.currentUser, {
    url: window.location.origin + '/signin',
  })
}

export const isVerificationRequired = (authUser) => {
  return authUser.providerData[0].providerId === 'password' && !authUser.emailVerified
}
