<script setup>
import { ref } from 'vue'
import FeedbackCard from '../atoms/FeedbackCard.vue'
import { sendGTagEvent } from '@/helpers/gtag'

const { title, options, propSelectedIndex, isWithFeedback } = defineProps([
  'title',
  'text',
  'options',
  'selectedIndex',
  'isWithFeedback'
])

const selectedIndex = ref(propSelectedIndex)

const emit = defineEmits(['selection'])

// TODO: update send only in gender selection, check text logic
function sendSelectionEventInCase(index) {
  if (options[index]?.sendGTag)
    sendGTagEvent({ event: 'gender_collected', gender: options[index].text })
  emit('selection', index)
}
</script>

<template>
  <div class="component-container">
    <div class="title">
      <p class="heading-5">{{ title }}</p>
      <p class="p-md" v-if="text">{{ text }}</p>
    </div>
    <FeedbackCard
      class="mt-24"
      v-if="isWithFeedback && selectedIndex !== undefined && options[selectedIndex].feedback"
      :title="options[selectedIndex].feedback.title"
      :text="options[selectedIndex].feedback.text"
    />
    <div class="mt-24">
      <button
        :class="`btn p-md mt-8 row ${selectedIndex === index ? 'selected' : ''} ${option.subtext ? 'with-subtext' : ''}`"
        v-for="(option, index) in options"
        :key="option"
        @click="
          () => {
            selectedIndex = index
            if (!isWithFeedback) sendSelectionEventInCase(index)
          }
        "
      >
        <div v-if="option.icon" class="heading-5">{{ option.icon }}</div>
        {{ option.text || option }}
        <span class="p-md subtext" v-if="option.subtext">{{ option.subtext }}</span>
      </button>
    </div>
    <button
      v-if="isWithFeedback"
      class="btn btn-primary label-md cnt-btn mt-16"
      :disabled="selectedIndex === undefined"
      @click="sendSelectionEventInCase(selectedIndex)"
    >
      Continue
    </button>
  </div>
</template>

<style scoped>
.title {
  padding: 16px 10px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.subtext {
  margin-left: auto;
  color: var(--neutrals-400);
}

.row {
  display: flex;
  flex-direction: row;
}
</style>
