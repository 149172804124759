<template>
  <span class="app-logo">
    <img src="@/assets/images/sparkle_icon.png" class="icon-24" alt="logo" />
    <span class="label-md">Sparkle</span>
  </span>
</template>

<style scoped>
.app-logo {
  display: flex;
  align-items: center;
  gap: 4px;
}

.label-md {
  color: var(--primary-base);
}

span {
  line-height: 24px;
}
</style>
