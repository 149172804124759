<script setup>
import { computed } from 'vue'
import ProgressBar from '../atoms/ProgressBar.vue'

const props = defineProps({
  sectionCount: Number,
  currentSectionText: String,
  summary: Object,
  currentStep: Number,
  isCurrentStepComplete: Boolean
})

const { summary } = props

const sectionCount = computed(() => {
  return Object.keys(summary.sections).length
})

const currentSection = computed(() => {
  return Object.keys(summary.sections).indexOf(props.currentSectionText)
})

const currentStepInSection = computed(() => {
  const totalStepsBefore =
    Object.keys(summary.sections)
      .slice(0, currentSection.value)
      .reduce((acc, section) => {
        return acc + summary.sections[section]
      }, 0) + summary.introSteps
  const currentCompletedSteps = props.currentStep + (props.isCurrentStepComplete ? 1 : 0)
  return currentCompletedSteps - totalStepsBefore
})

const currentSectionsCompletion = computed(() => {
  return (currentStepInSection.value / summary.sections[props.currentSectionText]) * 100
})

const calculatePercentage = (index) => {
  return index < currentSection.value
    ? 100
    : index > currentSection.value
      ? 0
      : currentSectionsCompletion.value
}
</script>

<template>
  <div class="indicator-container">
    <template v-for="index in sectionCount + 1" :key="index">
      <div
        :class="`circle ${index > currentSection + 1 + (currentSectionsCompletion == 100 ? 1 : 0) ? 'empty-circle' : ''}`"
      ></div>
      <span class="p-sm" v-if="currentSection + 1 == index">
        {{ currentSectionText }}
      </span>
      <ProgressBar
        :percentage="calculatePercentage(index - 1)"
        v-if="index !== sectionCount + 1"
        :key="'bar' + index"
      />
    </template>
  </div>
</template>

<style scoped>
.indicator-container {
  display: flex;
  gap: 8px;
  align-items: center;
  width: 100%;
}

.circle {
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: var(--primary-base);
  flex-shrink: 0;
}

.empty-circle {
  background-color: #fff;
  border: 1px solid var(--neutrals-200);
}

.p-sm {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: var(--primary-base);
}
</style>
