import axios from 'axios'
import { auth } from '@/plugins/firebase'
import { signInWithCustomToken } from 'firebase/auth'
import { isStaging } from './environment'
import { BACKEND_BASE_URL } from '@/constants/general'
import { performUserSignupActions } from '@/helpers/userManager.js'

export const createUserAndLogin = async (email, permission, trackerName) => {
  try {
    const response = await axios.post(BACKEND_BASE_URL + '/auth/create', { email, emailPermission: permission, trackerName })
    const responseData = response.data
    if (isStaging) console.log('user request', responseData)
    if (responseData.success) {
      if (isStaging) console.log('User created successfully', responseData);
      const userCredentials = await signInWithCustomToken(auth, responseData.customToken)
      if (isStaging) console.log('Signed in successfully', responseData);
      if (responseData.initialSet) {
        performUserSignupActions(userCredentials.user)
      }
    }
    return responseData
  } catch (error) {
    console.log(error)
  }
}