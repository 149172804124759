<script setup></script>
<template>
  <div class="component-container">
    <div class="testimonial-container mt-40">
      <img src="@/assets/images/social_proof_2_girls.png" class="info-img" alt="social-proof" />
      <p class="heading-4">
        Become the most <br />
        interesting person <br />
        in the room
      </p>
      <p class="p-md">Based on your answers, we crafted a self-<br />growth plan</p>
      <div class="box">
        <div><img src="@/assets/icons/five_stars.png" alt="five-stars" width="120px" /></div>
        <div class="other-people">
          <img src="@/assets/images/other_people.png" alt="person" width="92px" />
        </div>
        <p class="p-md">1103 people start growing in the last hour</p>
      </div>
    </div>
    <div class="btn btn-primary label-md cnt-btn" @click="$emit('cta')">Continue</div>
  </div>
</template>

<style scoped>
.testimonial-container {
  background-color: var(--neutrals-0);
  padding: 24px 8px;
  gap: 12px;
  border-radius: 16px;
  border: 1px solid var(--primary-light);
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.info-img {
  width: 100%;
}

.other-people {
  display: flex;
  gap: 5px;
  justify-content: center;
  align-items: center;
  color: #737373;
}

.heading-4 {
  text-align: center;
  line-height: 40px;
  color: var(--neutrals-900);
}

.p-md {
  color: var(--neutrals-700);
}

.box {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  width: 300px;
  border-radius: 16px;
  border: 1px solid var(--primary-light, #c2cdff);
  background: var(--primary-lighter);
}

.box > p {
  font-size: 14px;
  color: var(--neutrals-400);
}
</style>
