<script setup>
import { useUserStore } from '@/store/user'
import { sendEvent } from '@/helpers/cerebro'
import { computed, watchEffect, onMounted, ref, onBeforeUnmount } from 'vue'
import Paywall from '@/components/organisms/Paywall.vue'
import Header from '@/components/molecules/Header.vue'
import DiscountTimer from '@/components/organisms/DiscountTimer.vue'
import StarBadge from '@/assets/icons/star_badge.svg'
import InfiniteIcon from '@/assets/icons/infinite_icon.svg'
import HeadphonesIcon from '@/assets/icons/headphones_icon.svg'
import FAQSection from '@/components/organisms/FAQSection.vue'
import CommentCard from '@/components/molecules/CommentCard.vue'
import { sendGTagEvent } from '@/helpers/gtag'
import { steps } from '@/constants/onboarding.js'
import { onboardings, QUIZ_ID } from '@/constants/onboarding.js'
import { useProductStore } from '@/store/products'

const userStore = useUserStore()
const isPro = computed(() => userStore.isUserPro())
const componentKey = ref(0)
const isDiscounted = ref(true)
const isCheckout = ref(false)

const productStore = useProductStore()

const products = computed(() => {
  return productStore.getProductsWithSource('default')
})

watchEffect(() => {
  if (products.value.length !== 0) {
    sendEvent({
      eventName: 'paywall',
      properties: {
        source: isDiscounted.value ? 'countdown_discount_1' : 'default',
        product_ids: products.value.map((product) => product.id),
        default_product: products.value[1].id,
        product_prices: products.value.map(
          (product) => `$${(product.unit_amount / 100).toFixed(2)}`
        ),
        currency: 'USD',
        discount_rate: onboardings[QUIZ_ID].discountRate,
        layout: 'classic_layout',
        quiz_id: QUIZ_ID
      }
    })
  }
})

onMounted(() => {
  sendGTagEvent({ event: 'paywall_displayed' })

  sendEvent({
    eventName: 'page_displayed',
    properties: {
      page_name: 'payment',
      page_number: steps.length
    }
  })
})

onBeforeUnmount(() => {
  sendEvent({
    eventName: 'page_completed',
    properties: {
      page_name: 'payment',
      page_number: steps.length
    }
  })
})

const revertCheckout = () => {
  componentKey.value++
  isCheckout.value = !isCheckout.value
}

const discountFinished = () => {
  componentKey.value++
  isDiscounted.value = false
}

const comments = [
  {
    imgName: 'user-lily.png',
    user: 'Lily Harrison',
    comment: `Sparkle saves me so much time! I get all the key insights without reading the entire book.`,
    dateTxt: 'Jun 24, 2024'
  },
  {
    imgName: 'user-grace.png',
    user: 'Grace Walker',
    comment: `Perfect for my busy schedule—quick, informative summaries!`,
    dateTxt: 'July 12, 2024'
  },
  {
    imgName: 'user-owen.png',
    user: 'Owen Parker',
    comment: `Sparkle’s summaries are concise and engaging. Love it!`,
    dateTxt: 'Feb 2, 2024'
  },
  {
    imgName: 'user-ethan.png',
    user: 'Ethan Brooks',
    comment: `Great app for staying up-to-date with the latest books. Highly recommend! I’ve learned so much in such a short time thanks to Sparkle!`,
    dateTxt: 'May 17, 2024'
  }
]
</script>

<template>
  <Header />
  <div class="container">
    <DiscountTimer @discountEnded="discountFinished" @discountContinueClicked="revertCheckout" />
    <div class="card main-card mt-24">
      <div v-if="isDiscounted" class="discount-roof heading-6">
        <div class="img-container">
          <img src="../assets/images/discount_user.png" alt="discount_user" />
        </div>
        <p>Get your personal plan with 60% discount</p>
      </div>
      <Paywall
        :key="componentKey"
        theme="light"
        :isDiscounted="isDiscounted"
        :isCheckout="isCheckout"
      />
      <div class="features">
        <p class="heading-5">What you get with <span class="primary-color">Sparkle</span></p>
        <p class="p-md">Access limitless insights from bestseller books</p>
        <div class="features-list">
          <div class="text-with-badge">
            <div><StarBadge class="icon-24" /></div>
            <div>Best-selling book summaries</div>
          </div>
          <div class="text-with-badge">
            <div><InfiniteIcon class="icon-24" /></div>
            <div>Read with no limits</div>
          </div>
          <div class="text-with-badge">
            <div><HeadphonesIcon class="icon-24" /></div>
            <div>Listen on the go</div>
          </div>
        </div>
      </div>
      <FAQSection />
      <div class="social-proof-card">
        <p class="heading-5">People love the Sparkle app</p>
        <p>Become a member of our global community of million people</p>
        <div class="mini-social-proof">
          <div class="people">
            <img class="five-stars" src="@/assets/icons/five_stars.png" alt="five-stars" />
            <img class="other-people" src="@/assets/images/other_people.png" alt="other-people" />
            <p>Used by Millions</p>
          </div>
          <div class="stores">
            <div>
              <img src="@/assets/icons/apple_icon.png" alt="apple-icon" />
              <span class="heading-5" style="color: #0066ff">4.7</span>
            </div>
            <div>
              <img src="@/assets/icons/google_play_icon.png" alt="apple-icon" />
              <span class="heading-5" style="color: #4aa181">4.3</span>
            </div>
          </div>
        </div>
        <CommentCard
          v-for="comment in comments"
          :key="comment.user"
          :imgName="comment.imgName"
          :user="comment.user"
          :comment="comment.comment"
          :date="comment.dateTxt"
        />
      </div>
      <Paywall theme="dark" :isDiscounted="isDiscounted" />
    </div>
  </div>
</template>

<style scoped>
.container {
  width: 100%;
  max-width: 428px;
  margin: auto;
  margin-top: 12px;
  padding-left: 8px;
  padding-right: 8px;
  padding-bottom: 40px;
}

.discount-roof {
  position: absolute;
  top: 0px;
  display: flex;
  align-items: center;
  padding: 16px 12px;
  width: 100%;
  border-radius: 16px 16px 0px 0px;
  background: #f5af2a;
  gap: 16px;
  height: 99px;
}

.img-container {
  width: 84px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.heading-6 {
  color: var(--primary-darker);
}

.strike-text {
  color: var(--neutrals-500);
  text-decoration: line-through;
}

.features {
  background: #f5af2a33;
  border: 1px solid #f5af2a;
  padding: 32px 16px;
  text-align: center;
  border-radius: 12px;
  width: 100%;
}

.features-list {
  display: inline-block;
  text-align: left;
}

.text-with-badge {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 16px;
}

.mini-social-proof {
  display: flex;
  justify-content: space-between;
  gap: 16px;
  margin-top: 24px;
}

.mini-social-proof > div {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.people {
  padding: 12px;
  background-color: var(--primary-lighter);
  border: 1px solid var(--primary-light);
  border-radius: 16px;
  gap: 12px;
}

.stores {
  padding: 12px;
  background-color: #4aa18133;
  border: 1px solid #4aa18180;
  border-radius: 16px;
  gap: 15px;
}

.stores > div {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.five-stars {
  width: 120px;
}

.other-people {
  width: 92px;
}

.main-card {
  flex-direction: column;
  align-items: center;
  gap: 16px;
  padding: 32px 8px;
  position: relative;
}

.social-proof-card {
  padding: 8px;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 12px;
}
</style>
