<script setup>
import { ref, onMounted, watch } from 'vue'
import ProgressCircle from '../molecules/ProgressCircle.vue'
import QuoteCard from '@/components/atoms/QuoteCard.vue'
import YesNoModal from '../molecules/YesNoModal.vue'
import CheckBoxIcon from '@/assets/icons/checkbox_icon.svg'

const emit = defineEmits(['selection'])
const { title, popUpHeader, imgName, quote, items, options, questions } = defineProps([
  'title',
  'popUpHeader',
  'imgName',
  'quote',
  'items',
  'options',
  'questions'
])

const showModal = ref(false)
const currentQuestionIndex = ref(0)
const progress = ref(0)
const popUpAnswers = ref([])

function incrementProgressGradually(newProgressValue, totalDuration, onComplete) {
  const startTime = performance.now()
  const startValue = progress.value
  const valueIncrease = newProgressValue - startValue

  function step(timestamp) {
    const elapsed = timestamp - startTime
    const progressRatio = Math.min(elapsed / totalDuration, 1)
    progress.value = Math.floor(startValue + valueIncrease * progressRatio)

    if (progress.value < newProgressValue) {
      requestAnimationFrame(step)
    } else {
      progress.value = newProgressValue
      if (onComplete) onComplete() // Execute callback when animation is done
    }
  }
  requestAnimationFrame(step)
}

onMounted(() => {
  setTimeout(() => {
    incrementProgressGradually(Math.floor(Math.random() * 3) + 13, 700)
  }, 300)
  setTimeout(() => {
    showModal.value = true
  }, 850)
})

watch(progress, (newValue) => {
  if (newValue === 100 && currentQuestionIndex.value === questions.length) {
    showModal.value = false
    emit('selection', popUpAnswers.value)
  }
})

const handleYesNoProgress = (answer) => {
  showModal.value = false
  setTimeout(() => {
    currentQuestionIndex.value++
    let newProgressValue = currentQuestionIndex.value * 25 + (Math.floor(Math.random() * 3) + 13)
    newProgressValue = Math.min(newProgressValue, 100)
    incrementProgressGradually(newProgressValue, 1600, () => {
      if (currentQuestionIndex.value < questions.length) {
        showModal.value = true
      }
    })
    popUpAnswers.value.push(questions[currentQuestionIndex.value - 1].qID + '-' + answer)
  }, 300)
}

const isPopUpStepDone = (index) => {
  const expectedProgress = (index + 1) * (100 / items.length)
  return progress.value >= expectedProgress
}
</script>

<template>
  <div class="loading-container">
    <ProgressCircle :progress="progress" :imgName="imgName" />
    <p class="heading-5">{{ title }}</p>
    <TransitionGroup name="group" tag="div" class="items" appear
      ><div
        v-for="(item, index) in items"
        :key="index"
        class="item"
        :style="{ transitionDelay: `${index * 0.2}s` }"
      >
        <div class="left-row">
          <p class="left-item-icon">{{ item.icon }}</p>
          <p class="left-item-text">{{ item.title }}</p>
        </div>
        <div v-if="isPopUpStepDone(index)" class="right-row">
          <p class="label-md">Done</p>
          <CheckBoxIcon />
        </div>
        <div v-else class="right-row">
          <div class="label-md">Loading...</div>
        </div>
      </div></TransitionGroup
    >
    <QuoteCard class="mt-20" :author="quote.author" :text="quote.text" :imgName="quote.imgName" />

    <YesNoModal
      v-if="showModal"
      :questions="questions"
      :options="options"
      :info-text="popUpHeader"
      :current-question-index="currentQuestionIndex"
      @progress="handleYesNoProgress"
    ></YesNoModal>
  </div>
</template>

<style scoped>
.loading-container {
  align-self: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
}

.loading-container > * {
  width: 100%;
  max-width: 480px;
}

.items {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 12px;
  gap: 4px;
  padding-left: 20px;
  padding-right: 20px;
}

.item {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  height: 56px;
}

.left-row {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  margin-right: auto;
}

.right-row {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  color: var(--neutrals-300);
}

.right-row > p {
  color: var(--primary-base);
}

.heading-5 {
  margin-top: 12px;
  padding-left: 20px;
  padding-right: 20px;
}
</style>
