<script setup>
import { onMounted } from 'vue'
import { onAuthStateChanged } from '@firebase/auth'
import { useUserStore } from '@/store/user'
import { useRouter } from 'vue-router'
import { isStaging } from '@//helpers/environment'
import { initializePaddle } from '@//helpers/paddle.js'
import { sendGTagEvent } from '@/helpers/gtag'
import { sendEvent } from '@/helpers/cerebro'
import { auth } from '@/plugins/firebase'

const router = useRouter()

onMounted(() => {
  initializePaddle()
  const userStore = useUserStore()
  sendGTagEvent({
    event: 'flow_started'
  })

  sendEvent({
    eventName: 'onboarding_started'
  })

  onAuthStateChanged(auth, async (user) => {
    if (isStaging) {
      console.log('::USER::', user)
    }
    if (user) {
      localStorage.setItem('cdw_iuli', true)
      userStore.setUser(user, false, router.options.history.state.current)
    } else {
      localStorage.removeItem('cdw_iuli')
      userStore.setUser(null)
    }
  })
})
</script>

<template>
  <metainfo>
    <template v-if="content" v-slot:title="{ content }">{{ content }}</template>
  </metainfo>
  <router-view></router-view>
</template>
