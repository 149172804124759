import { isPreProd, isStaging } from '../helpers/environment'

// const authDomain = isStaging
//   ? import.meta.env.MODE === 'development'
//     ? 'codeway-template.firebaseapp.com'
//     : 'website-staging.codeway-template.web.app'
//   : isPreProd
//     ? 'website-preprod.codeway-template.web.app'
//     : 'codeway-template.web.app'

export const firebaseConfig = {
  apiKey: "AIzaSyBQ3N55dVddvkEGsInLn8nKKvlQt51hx5I",
  authDomain: "ai-book-codeway.firebaseapp.com",
  projectId: "ai-book-codeway",
  storageBucket: "ai-book-codeway.appspot.com",
  messagingSenderId: "103486945207",
  appId: "1:103486945207:web:b23350dd6d6c820cab5a32",
  measurementId: "G-2WGT2L2LQS"
};