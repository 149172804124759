import { sendGTagEvent } from '../helpers/gtag'
import { sha256 } from '../helpers/crypto'
import { getCerebroId, sendEvent, sendUserEvent } from '../helpers/cerebro'
import verifyPayment from '@/helpers/paymentVerification'
import { initializePaddle } from "./paddle";
import { useUserStore } from '@/store/user'
import { requestWithIdToken } from './requestWithIdToken'
import * as Sentry from '@sentry/vue'


const providerToName = {
  password: 'mail',
  'google.com': 'google',
  'facebook.com': 'facebook',
  'apple.com': 'apple',
}

export const setUserData = async (userData) => {
  return await requestWithIdToken({
    method: 'POST',
    path: '/user/set',
    data: userData,
  })
}

export const getUserData = async (uid) => {
  return await requestWithIdToken({
    method: 'POST',
    path: '/user/get',
    data: { userId: uid },
  })
}


export const setUserClickIds = async userClickIds => {
  return await requestWithIdToken({
    method: 'POST',
    path: '/user/set-click-ids',
    data: userClickIds,
  })
}

export const loginActions = async (authUser, dataToSet, isJustRefetch, source) => {
  // add email of user to the dataLayer
  sendGTagEvent({ event: 'email_collected', em: await sha256(authUser.email) })
  sendEvent({
    eventName: 'email_collected',
    properties: {
      e_mail_adress: authUser.email,
    }
  })
  if (authUser.providerData[0].providerId === 'password') {
    sendEvent({
      eventName: 'sign_in',
      eventGroup: 'authentication',
    })
    sendGTagEvent({ event: 'sign_in', user_id: authUser.uid })
  }

  const clickIds = getClickIds()

  if (Object.keys(clickIds).length !== 0) {
    setUserClickIds({
      webId: 'com.codespaceapps.aibookweb',
      userId: authUser.uid,
      clickIds: {
        email: authUser.email,
        ...clickIds,
      },
    })
  }

  // get User Data from db
  const userDataResponse = await getUserData(authUser.uid)
  const userDataPayload =
    userDataResponse && userDataResponse.data && userDataResponse.data.payload
      ? userDataResponse.data.payload
      : undefined
  if (userDataPayload && !userDataPayload.cerebroId) {
    dataToSet.cerebroId = getCerebroId()
  } else if (userDataPayload && userDataPayload.cerebroId) {
    const userStore = useUserStore()
    userStore.setCerebroId(userDataPayload.cerebroId)
  }

  if (userDataPayload) {
    sendEvent({ eventName: 'user_data_result' })

    // Get subscription info from Payment Verifier
    const verificationResponse = await verifyPayment()
    if (verificationResponse) {
      userDataPayload.subscription = verificationResponse
      dataToSet.subscription = verificationResponse
    } else {
      userDataPayload.subscription = null
      dataToSet.subscription = null
    }
  }

  if (!isJustRefetch) {
    // send user event to cerebro
    const cerebroUserData = { user_id: getCerebroId() }
    if (userDataPayload) {
      cerebroUserData['account_id'] = authUser.uid
    }
    await sendUserEvent(cerebroUserData)

    // set user data to db
    setUserData(dataToSet)
    Sentry.setUser({
      email: authUser.email,
      userId: authUser.uid,
      cerebroId: getCerebroId(),
    })

    // if user has paddleCustomerId reinitialize paddle
    if (userDataPayload && userDataPayload.paddleCustomerId) {
      initializePaddle(userDataPayload.paddleCustomerId);
    }
  }

  return userDataPayload
}

export const performUserSignupActions = authUser => {
  const clickIds = getClickIds()

  if (Object.keys(clickIds).length !== 0) {
    setUserClickIds({
      webId: 'com.codespaceapps.aibookweb',
      userId: authUser.uid,
      clickIds: {
        email: authUser.email,
        ...clickIds,
      },
    })
  }
  sendGTagEvent({ event: 'sign_up', user_id: authUser.uid })
  sendEvent({
    eventName: 'sign_up',
    eventGroup: 'authentication',
    user_id: authUser.uid,
  })
}

const getClickIds = () => {
  const data = {
    gclid: getCookie('_p2s_gcl') || localStorage.getItem('_p2s_gcl'),
    fbc: getCookie('_fbc') || localStorage.getItem('_fbc'),
    fbp: getCookie('_fbp') || localStorage.getItem('_fbp'),
    ttclid: getCookie('ttclid') || localStorage.getItem('ttclid'),
    ttp: getCookie('_ttp') || localStorage.getItem('_ttp'),
  }

  return Object.fromEntries(
    Object.entries(data).filter(([_, value]) => value != null),
  )
}

const getCookie = name => {
  const nameEQ = name + '='
  const cookies = document.cookie.split(';')
  for (let cookie of cookies) {
    cookie = cookie.trim() // Remove leading spaces
    if (cookie.startsWith(nameEQ)) {
      return cookie.substring(nameEQ.length)
    }
  }
  return null
}