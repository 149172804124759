<script setup>
import ProductBox from '@/components/molecules/ProductBox.vue'
import { useProductStore } from '@/store/products'
import { useUserStore } from '@/store/user'
import { sendEvent } from '@/helpers/cerebro'
import { ref, computed, onMounted, watch } from 'vue'
import PaddlePaymentElement from '../molecules/PaddlePaymentElement.vue'
import SecureCheckIcon from '@/assets/icons/secure_check.svg'
import Modal from './Modal.vue'

import { discount } from '@/config/products'
import { changeEmail } from '@/services/authService'
import { useToast } from 'vue-toast-notification'
import 'vue-toast-notification/dist/theme-sugar.css'
import { onboardings, QUIZ_ID } from '@/constants/onboarding.js'

const {
  isDiscounted,
  theme,
  isCheckout: propIsCheckout
} = defineProps(['isDiscounted', 'theme', 'isCheckout'])

const style = computed(() => ({
  '--primary-color': theme === 'dark' ? 'var(--primary-darker)' : 'white', //background color
  '--secondary-color': theme === 'dark' ? 'white' : 'var(--primary-lighter)', // selected product background color
  '--accent-color': theme === 'dark' ? 'var(--primary-lighter)' : 'var(--primary-darker)', //tag bg, border colors
  'accent-color-2': theme === 'dark' ? 'white' : 'var(--primary-darker)', //button color
  '--text-primary': theme === 'dark' ? 'white' : 'var(--neutrals-900)', // main text color
  '--text-secondary': theme === 'dark' ? 'var(--neutrals-900)' : 'white', //tag text color
  '--text-dark-primary': 'var(--neutrals-900)', // for always dark colors
  '--text-light-primary': 'white', // for always light colors,
  marginTop: (theme === 'light') & isDiscounted ? '75px' : ''
}))

const toast = useToast()
const userStore = useUserStore()
const isCheckout = ref(propIsCheckout ?? false)

const status = ref('idle')
const selectedIndex = ref(1)

const paywallStructure = computed(() =>
  JSON.parse(userStore.getParameter('paywall_structure') || '{}')
)

const isPro = computed(() => userStore.isUserPro())
const productStore = useProductStore()
const isPaymentInitiated = computed(() => userStore.userData.isPaymentInitiated)
const remountKey = ref(0)

// make source dynamic for different sources
const source = computed(() => (isDiscounted ? 'special-offer-1' : 'default'))
const products = computed(() => {
  return productStore.getProductsWithSource(source.value)
})

const initiateAndRedirectToCheckout = async (product) => {
  if (status.value !== 'idle') return
  status.value = 'initiating'

  sendEvent({
    eventName: 'purchase_started',
    properties: {
      product_id: product.id,
      default_product: products.value[1].id,
      product_price: `$${((product.unit_amount / 100) * (1 - onboardings[QUIZ_ID].discountRate)).toFixed(2)}`,
      currency: product.currency ?? 'USD',
      discount_rate: onboardings[QUIZ_ID].discountRate,
      layout: 'classic_layout',
      source: source.value
    }
  })

  status.value = 'idle'
  isCheckout.value = true
  sendEvent({ eventName: 'checkout', properties: { source: source.value } })
}

onMounted(() => {
  selectedIndex.value = parseInt(
    paywallStructure.value?.[source.value]?.selected_index ?? selectedIndex.value
  )
})

watch(
  () => propIsCheckout,
  () => {
    isCheckout.value = true
  }
)

watch(
  () => {
    source.value, products.value
  },
  () => {},
  { deep: true }
)

const selectProduct = (index) => {
  const product = products.value[index]
  sendEvent({
    eventName: 'select_plan',
    properties: {
      product_id: product.id,
      source: source.value,
      billing_cycle:
        (product.recurring && product.recurring.interval_count) +
        '_' +
        (product.recurring && product.recurring.interval),
      price: `$${(product.unit_amount / 100).toFixed(2)}`
    }
  })
  selectedIndex.value = index
}

const usualPrice = computed(() => {
  return products.value[selectedIndex.value].unit_amount / 100
})

const checkoutParams = computed(() => {
  const product = products.value[selectedIndex.value]
  return {
    product,
    price: product.unit_amount,
    interval: product.recurring && product.recurring.interval,
    intervalCount: product.recurring && product.recurring.interval_count,
    offersFreeTrial: product.offersFreeTrial,
    productId: product.id,
    source: source.value,
    isDiscounted: isDiscounted,
    discountId: discount,
    isMostPopular: selectedIndex.value === 1
  }
})

const emailMismatch = computed(() => userStore.getEmailMismatch())
const correctEmail = ref(userStore.userData.email ?? '')
const isCorrectEmailValid = ref(userStore.userData.email ? true : false)

const discountedPrice = computed(() =>
  (
    (products.value[selectedIndex.value].unit_amount *
      (isDiscounted ? 1 - onboardings[QUIZ_ID].discountRate : 1)) /
    100
  ).toFixed(2)
)

watch(correctEmail, (newVal) => {
  const emailRegex = /^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/
  if (emailRegex.test(newVal)) {
    isCorrectEmailValid.value = true
  } else {
    isCorrectEmailValid.value = false
  }
})

const submitNewEmail = async () => {
  if (!isCorrectEmailValid.value) return
  if (correctEmail.value === userStore.userData.email) {
    toast.success('Email updated successfully', { position: 'top-right' })
    userStore.setEmailMismatch(false)
    return
  }
  try {
    const resp = await changeEmail(correctEmail.value)
    if (resp.success) {
      if (resp.redirectLink) userStore.setRedirectLink(resp.redirectLink)
      toast.success('Email updated successfully', { position: 'top-right' })
    } else {
      toast.error('There is a already registered account with this email', {
        position: 'top-right'
      })
    }
    userStore.setEmailMismatch(false)
  } catch (error) {
    toast.error('Something went wrong, please try again later', {
      position: 'top-right'
    })
    userStore.setEmailMismatch(false)
  }
}

watch(
  () => userStore.userData.email,
  (newEmail, oldEmail) => {
    if (newEmail !== oldEmail) {
      remountKey.value++
      Paddle.Checkout.updateCheckout({
        customer: {
          email: newEmail
        }
      })
    }
  }
)
</script>

<template>
  <div class="container" :style="style">
    <p class="heading-5 center-text">Unlock Unlimited Access</p>
    <div v-if="!isPro && products && products.length > 0" class="products mt-16">
      <ProductBox
        v-for="(product, index) in products"
        :key="product.id"
        :product="product"
        :onboardingStyle="true"
        :isSelected="index === selectedIndex"
        class="each-product"
        :selectProduct="() => selectProduct(index)"
        :isMostPopular="index === 1"
        :discountAmount="0.6"
        :isDiscounted="isDiscounted"
      />
      <button
        @click="() => initiateAndRedirectToCheckout(products[selectedIndex])"
        class="btn btn-primary label-md"
        :class="`${theme === 'dark' ? 'dark' : ''} `"
      >
        Continue
      </button>
      <div class="payment-methods">
        <div class="secure-badge"><SecureCheckIcon /> &nbsp;Pay safe & secure</div>
        <img src="@/assets/images/payment_methods.png" alt="payment-methods" />
      </div>
      <div class="subscription-info" v-if="isDiscounted">
        <p class="p-xs">
          We’ve automatically applied discount to your first subscription price. Please note that
          your subscription will be automatically renewed at discounted price of ${{
            discountedPrice
          }}
          at the end of chosen subscription term.
        </p>
      </div>
    </div>
    <div>
      <Modal :isVisible="isCheckout" @close="isCheckout = false">
        <PaddlePaymentElement :key="remountKey" v-bind="checkoutParams" />
      </Modal>
      <Modal :isVisible="emailMismatch == true ?? false" :closeIcon="false">
        <div class="email-mismatch">
          <h2 class="heading-5">Email Mismatch</h2>
          <p class="p-md">
            The email doesn't match. <br />
            Please enter the correct email:
          </p>
          <input
            type="email"
            placeholder="Enter your correct email"
            class="p-md email-input"
            v-model="correctEmail"
          />
          <button class="btn btn-primary" :disabled="!isCorrectEmailValid" @click="submitNewEmail">
            Submit
          </button>
        </div>
      </Modal>
    </div>
  </div>
</template>

<style scoped>
.container {
  color: var(--text-primary);
  border-radius: 16px;
  background-color: var(--primary-color);
  padding-top: 24px;
  padding-bottom: 24px;
}

.products {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}

.payment-methods {
  text-align: center;
  padding-bottom: 12px;
}

.secure-badge {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: #4aa181;
  border: 1px solid #4aa181;
  padding: 6px 8px;
  border-radius: 8px;
  margin: 16px 0px 12px 0px;
  background: #00bb770d;
}

.subscription-info {
  text-align: center;
  padding: 8px 16px;
  color: var(--text-dark-primary);
  border: 1px solid var(--primary-color);
  background: var(--secondary-color);
  opacity: 0.5;
  border-radius: 12px;
}

.dark {
  background: var(--neutrals-0, #fff);
  color: var(--primary-color);
}

.dark:hover {
  background: var(--primary-light);
}

.email-mismatch {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 40px 20px;
  width: 100%;
  max-width: 480px;
}

.email-mismatch > h2 {
  min-width: 340px;
}

.email-input {
  padding: 0px 10px;
  height: 55px;
  border: 1px solid var(--neutrals-200);
  width: 100%;
}
</style>
