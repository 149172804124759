<script setup>
import { useUserStore } from '@/store/user'
import { sendEvent } from '@/helpers/cerebro'
import { requestWithIdToken } from './../helpers/requestWithIdToken'
import { auth } from '@/plugins/firebase'
import {
  signOut,
  updatePassword,
  deleteUser,
  reauthenticateWithCredential,
  EmailAuthProvider
} from 'firebase/auth'
import { ref, computed } from 'vue'
import { storeToRefs } from 'pinia'
import verifyPayment from '@/helpers/paymentVerification'
import { RouterLink, useRouter } from 'vue-router'
import { SUPPORT_EMAIL } from '@/constants/general'
import Header from '@/components/molecules/Header.vue'
import Divider from '@/components/atoms/Divider.vue'
import Modal from '@/components/organisms/Modal.vue'

const userStore = useUserStore()
const { userData } = storeToRefs(userStore)

const router = useRouter()
const isPro = computed(() => userStore.isUserPro())
const isSubscribedRecurring = computed(() => userStore.isSubscribedRecurring())

const subscription = computed(() => userStore.getSubscription())

const status = ref('idle')

const existingPasswordForDelete = ref('')
const existingPasswordForChange = ref('')
const newPassword = ref('')
const newPasswordConfirm = ref('')

const errMsgCurrentPassword = ref('')
const errMsgNewPassword = ref('')
const succusfullMsg = ref('')
const showDeleteModal = ref(false)
const showChangePasswordModal = ref(false)

const tab = ref('subscription')

const showPassword = ref(false)
let timeoutId = null
const toggleShowPassword = () => {
  showPassword.value = !showPassword.value
  if (showPassword.value) {
    if (timeoutId !== null) {
      clearTimeout(timeoutId)
    }
    timeoutId = setTimeout(() => {
      showPassword.value = false
      timeoutId = null
    }, 8000)
  }
}

const getPlanType = () => {
  if (isPro.value) {
    const interval = userStore.getSubscription().interval
    let billingFrequency
    if (interval.includes('year')) {
      billingFrequency = 'Yearly Plan'
    } else if (interval.includes('month')) {
      billingFrequency = 'Monthly Plan'
    } else {
      billingFrequency = 'Weekly Plan'
    }
    return billingFrequency
  } else {
    return ''
  }
}

const updateShowDeleteModal = (rhs) => {
  showDeleteModal.value = rhs

  if (!rhs) {
    errMsgCurrentPassword.value = ''
    existingPasswordForDelete.value = ''
  }
}

const updateShowChangePasswordModal = (rhs) => {
  showChangePasswordModal.value = rhs

  if (!rhs) {
    errMsgCurrentPassword.value = ''
    errMsgNewPassword.value = ''

    existingPasswordForChange.value = ''
    newPassword.value = ''
    newPasswordConfirm.value = ''
  }
}

const userEmail = computed(() => {
  return userData.value.email
})

const isDeleteButtonDisabled = computed(() => {
  return !existingPasswordForDelete.value
})

const isChangePasswordButtonDisabled = computed(() => {
  return !existingPasswordForChange.value || !newPassword.value || !newPasswordConfirm.value
})

const inputPasswordType = computed(() => {
  return showPassword.value ? 'text' : 'password'
})

const handleChangePassword = () => {
  if (!existingPasswordForChange.value.trim()) {
    errMsgCurrentPassword.value = 'Password cannot be empty.'
    setTimeout(() => {
      errMsgCurrentPassword.value = ''
    }, 5000)
    return
  }

  const trimmedPassword = newPassword.value.trim()
  if (!trimmedPassword) {
    errMsgNewPassword.value = 'Password cannot be empty.'
    setTimeout(() => {
      errMsgNewPassword.value = ''
    }, 5000)
    return
  }

  if (newPassword.value != newPasswordConfirm.value) {
    errMsgNewPassword.value = 'Password must be same.'
    setTimeout(() => {
      errMsgNewPassword.value = ''
    }, 5000)
    return
  }

  if (trimmedPassword == existingPasswordForChange.value.trim()) {
    errMsgNewPassword.value = 'New password can not be same.'
    setTimeout(() => {
      errMsgNewPassword.value = ''
    }, 5000)
    return
  }

  const user = auth.currentUser
  const credential = EmailAuthProvider.credential(
    auth.currentUser.email,
    existingPasswordForChange.value
  )

  reauthenticateWithCredential(user, credential)
    .then(() => {
      updatePassword(auth.currentUser, trimmedPassword)
        .then(() => {
          succusfullMsg.value = 'Password changed successfully'
          sendEvent({
            eventName: 'password_changed'
          })
          setTimeout(() => {
            succusfullMsg.value = ''
          }, 5000)
          updateShowChangePasswordModal(false)
        })
        .catch((error) => {
          if (error.code === 'auth/weak-password') {
            errMsgNewPassword.value = 'Password should be minimum 8 characters.'
          } else {
            errMsgNewPassword.value = error.message || 'Please try again.'
          }
          setTimeout(() => {
            errMsgNewPassword.value = ''
          }, 5000)
        })
    })
    .catch((error) => {
      errMsgCurrentPassword.value = '*Wrong Password'
      setTimeout(() => {
        errMsgCurrentPassword.value = ''
      }, 5000)
      return
    })
}

const handleDeleteUser = async () => {
  if (!existingPasswordForDelete.value.trim()) {
    errMsgCurrentPassword.value = 'Password can not be empty.'
    setTimeout(() => {
      errMsgCurrentPassword.value = ''
    }, 5000)
    return
  }

  if (subscription.value != null) {
    if (!subscription.value.subscriptionId.startsWith('pi') && subscription.value.isAutoRenewOn) {
      try {
        await cancelSubscription(
          subscription.value.subscriptionId,
          subscription.value.isAutoRenewOn
        )
      } catch (error) {
        console.log(error)
        return alert('settingsSubscriptionCancelError')
      }
    }
    console.log('Subscription succesfully cancelled')
  }

  const user = auth.currentUser
  const credential = EmailAuthProvider.credential(
    auth.currentUser.email,
    existingPasswordForDelete.value
  )

  reauthenticateWithCredential(user, credential)
    .then(() => {
      deleteUser(auth.currentUser)
        .then(() => {
          succusfullMsg.value = 'User deleted successfully'
          sendEvent({
            eventName: 'account_deleted'
          })
          setTimeout(() => {
            succusfullMsg.value = ''
          }, 5000)
          updateShowDeleteModal(false)
          errMsgCurrentPassword.value = ''

          router.push({ name: 'OnboardingPage' })
        })
        .catch((error) => {
          errMsgCurrentPassword.value = error.message || 'Please try again.'
          setTimeout(() => {
            errMsgCurrentPassword.value = ''
          }, 5000)
        })
    })
    .catch((error) => {
      errMsgCurrentPassword.value = '*Wrong Password'
      setTimeout(() => {
        errMsgCurrentPassword.value = ''
      }, 5000)
      return
    })
}

const handleSignout = async () => {
  await sendEvent({
    eventName: 'signout',
    properties: {
      source: router.currentRoute.value.name,
      firebaseId: auth.currentUser.uid
    }
  })
  signOut(auth).then(() => {})
  router.push({ name: 'OnboardingPage' })
}

const handleStopCancelSubscription = async (subscription, id) => {
  status.value = 'busy'
  if (subscription.isAutoRenewOn) {
    sendEvent({ eventName: 'cancel_subscription_tapped', properties: { source: 'Settings' } })
    await cancelSubscription(id, subscription.isAutoRenewOn ?? false)
  } else {
    sendEvent({ eventName: 'stop_cancelation_tapped', properties: { source: 'Settings' } })
    await stopCancellation(id, subscription.isAutoRenewOn ?? false)
  }
  status.value = 'idle'
}

const cancelSubscription = async (subscriptionId, isAutoRenewOn) => {
  try {
    await requestWithIdToken({
      path: '/paddle/cancel-subscription',
      method: 'POST',
      data: {
        subscriptionId
      }
    })
    await sendVerificationRequest({ isAutoRenewOn: isAutoRenewOn })
  } catch (error) {
    console.log(error)
    sendEvent({ eventName: 'cancel_subs_error', eventGroup: 'subscription', properties: {} })
    alert(
      `Error occured while cancelling subscription. If the problem persists, please contact support at ${SUPPORT_EMAIL}`
    )
  }
}

async function sendVerificationRequest({ sessionId, isAutoRenewOn }) {
  try {
    for (let i = 0; i < 10; i++) {
      const verificationResponse = await verifyPayment(sessionId)
      if (verificationResponse) {
        userStore.editUserData({ subscription: verificationResponse })
      } else {
        userStore.editUserData({ subscription: null })
      }
      if (verificationResponse !== null && isAutoRenewOn !== verificationResponse.isAutoRenewOn) {
        break
      }
      await new Promise((resolve) => setTimeout(resolve, 300))
    }
  } catch (e) {
    return
  }
}

const stopCancellation = async (subscriptionId, isAutoRenewOn) => {
  try {
    await requestWithIdToken({
      path: '/paddle/stop-cancellation',
      method: 'POST',
      data: {
        subscriptionId
      }
    })
    await sendVerificationRequest({ isAutoRenewOn: isAutoRenewOn })
  } catch (error) {
    console.log(error)
    sendEvent({ eventName: 'stop_cancellation_error', eventGroup: 'subscription', properties: {} })
    alert(
      `Error occured while cancelling subscription. If the problem persists, please contact support at ${SUPPORT_EMAIL}`
    )
  }
}

const changeTab = (tabName) => {
  tab.value = tabName
}
</script>

<template>
  <Header />
  <div class="container">
    <p class="heading-3">My account</p>
    <p class="faded">See and manage your account.</p>
    <div class="tabs">
      <button
        class="btn btn-sm btn-fluid"
        :class="{ 'btn-flat': tab !== 'subscription' }"
        @click="changeTab('subscription')"
      >
        Subscription
      </button>
      <button
        class="btn btn-sm btn-fluid"
        :class="{ 'btn-flat': tab === 'subscription' }"
        @click="changeTab('account')"
      >
        Account
      </button>
    </div>
    <Divider />
    <div class="card" v-if="tab === 'subscription'">
      <p>Email</p>
      <p class="faded">{{ userEmail }}</p>
      <p>Subscription</p>
      <div v-if="isPro" class="subscription-details">
        <div>
          <p class="label-sm faded">Status</p>
          <p class="neutral-400">{{ subscription.isAutoRenewOn ? 'Active' : 'Set to cancel' }}</p>
        </div>
        <div>
          <p class="label-sm faded">Plan</p>
          <p class="neutral-400">{{ getPlanType() }}</p>
        </div>
        <div>
          <p class="label-sm faded">Period End</p>
          <p class="neutral-400">
            {{
              isSubscribedRecurring ? new Date(subscription.expiresAt).toLocaleDateString() : '-'
            }}
          </p>
        </div>
        <div class="primary">Sparkle Pro</div>
      </div>

      <!-- Not subscribed -->
      <div v-else class="faded">
        <p>You are on free plan</p>
      </div>
    </div>
    <div v-if="tab == 'subscription'">
      <button
        v-if="isPro"
        class="btn"
        :class="{ 'btn-primary': !subscription.isAutoRenewOn }"
        @click="handleStopCancelSubscription(subscription, subscription.subscriptionId)"
      >
        {{ subscription.isAutoRenewOn ? 'Cancel subscription' : 'Renew subscription' }}
      </button>
      <div v-else>
        <RouterLink :to="{ name: 'Payment' }">
          <button class="btn btn-primary">Get Started</button>
        </RouterLink>
      </div>
    </div>
    <div v-else>
      <div class="card">
        <p>Email</p>
        <p class="faded">{{ userEmail }}</p>
      </div>
      <div class="account-actions mt-16">
        <button class="btn" @click="handleSignout">Log out</button>
        <button
          class="btn btn-danger"
          @click="
            () => {
              sendEvent({
                eventName: 'click_delete_account'
              })
              updateShowDeleteModal(true)
            }
          "
        >
          Delete Account
        </button>
      </div>
    </div>

    <div class="center-text">
      <p class="label-sm faded">Download the app</p>
      <div class="mt-16">
        <a href="https://app.adjust.com/1hlxmqrn" target="_blank">
          <img
            src="@/assets/images/app_store_cta.png"
            alt="Download on the App Store"
            class="store-cta"
          />
        </a>
        <a href="https://app.adjust.com/1hlxmqrn" target="_blank">
          <img
            src="@/assets/images/google_play_cta.png"
            alt="Get it on Google Play"
            class="store-cta"
          />
        </a>
      </div>
    </div>
  </div>

  <Modal :isVisible="showDeleteModal" @close="updateShowDeleteModal(false)">
    <div class="modal">
      <div class="modal-content">
        <p class="modal-top-text">Are you sure you want to delete your account?</p>
        <p class="modal-bottom-text mt-20">
          This action will permanently delete your account and your data.
        </p>

        <div class="p-relative">
          <input
            class="form-control mt-20"
            :type="inputPasswordType"
            placeholder="Enter your password"
            name="password"
            id="passwordExistingDelete"
            v-model="existingPasswordForDelete"
          />
        </div>

        <p v-if="errMsgCurrentPassword" class="error-message">{{ errMsgCurrentPassword }}</p>

        <div class="modal-buttons mt-20">
          <button
            class="modal-cancel"
            @click="
              () => {
                sendEvent({
                  eventName: 'cancel_delete_account'
                })
                updateShowDeleteModal(false)
              }
            "
          >
            Cancel
          </button>
          <button
            class="modal-delete-user"
            @click="handleDeleteUser"
            :disabled="isDeleteButtonDisabled"
          >
            Delete Account
          </button>
        </div>
      </div>
    </div>
  </Modal>
</template>

<style scoped>
.container {
  max-width: 428px;
  margin: auto;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 12px;
}

.store-cta {
  width: 135px;
  height: 45px;
  margin-left: 5px;
  margin-right: 5px;
}

.faded {
  color: var(--neutrals-500);
}

.tabs {
  display: flex;
  gap: 16px;
}

.btn-flat {
  background-color: transparent;
  border: none;
  color: var(--neutrals-500);
}

.card {
  flex-direction: column;
  gap: 12px;
}

.subscription-details {
  display: block;
  padding: 8px;
  margin-top: -12px;
}

.subscription-details div {
  width: 50%;
  display: inline-block;
  flex-direction: column;
  gap: 8px;
  margin-top: 12px;
}

.neutral-400 {
  color: var(--neutrals-400);
}

.account-actions {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.btn-danger {
  background: #ffe4e0;
  color: #eb2525;
}

.modal-content {
  background: white;
  border-radius: 20px;
  position: relative;
  max-height: 100vh;
  max-width: 100vw;
}

.modal {
  padding: 50px 50px 40px 50px;
  border-radius: 20px;
  background: #fff;
  width: 480px;
}

.modal-top-text {
  color: #12142a;
  text-align: center;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.modal-bottom-text {
  color: rgba(18, 20, 42, 0.4);
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.modal-section-text {
  color: rgba(18, 20, 42, 0.4);
  text-align: left;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.features {
  margin-top: auto;
}

.modal-buttons {
  display: flex;
  flex-direction: row;
  gap: 20px;
  justify-content: center;
  align-items: center;
}

.modal-cancel {
  flex: 1;
  display: flex;
  width: 162px;
  height: 50px;
  padding: 15px 25px;
  justify-content: center;
  align-items: center;
  gap: 5px;
  border-radius: 12px;
  background: rgba(18, 20, 42, 0.07);
  border: none;

  color: #12142a;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.modal-delete-user {
  flex: 1;
  display: flex;
  height: 50px;
  padding: 15px 25px;
  justify-content: center;
  align-items: center;
  gap: 5px;
  border-radius: 12px;
  background: #ea4335;
  border: none;
  color: #fff;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
</style>
