import { discount } from '@/config/products'

export const steps = [
  {
    name: 'age_selection',
    component: 'AgeSelection',
    props: {
      options: ['18-24', '25-34', '35-44', '45+'],
      title: 'Stand out as the most interesting person in the room.'
    },
  },
  {
    name: 'testimonial_selection',
    component: 'TestimonialSection',
    props: {
      imgName: 'book_reading',
      title: 'Sparkle is a tool for acquiring and absorbing as much information as possible',
      text: 'We’ll help you with self-growth. First, let’s dive into your personality and tailor your personal plan.'
    }
  },
  {
    name: 'gender_selection',
    component: 'SelectOneList',
    props: {
      options:
        [
          { sendGTag: true, icon: '👱‍♂️', text: 'Male' },
          { sendGTag: true, icon: '👱‍♀️', text: ' Female' },
          { sendGTag: true, icon: '🧑‍🦳', text: ' Other' },
        ],
      title: 'Select your gender'
    },
    dynamicResponse: false,
    responsePage: {
      component: 'InformationCard',
      props: {
        imgName: 'book_reading',
        title: 'Yay, glad you are here!',
        text: 'We’ll help you with self-growth. First, let’s dive into your personality and tailor your personal plan.'
      }
    },
    section: 'Profile'
  },
  {
    name: 'areas_selection',
    component: 'MultiSelectCard',
    props: {
      options: [
        'Emotions',
        'Motivation',
        'Nutrition',
        'Habits',
        'Love & relationships',
        'Mindset',
        'Exercise',
        'Empathy',
        'Self-care',
        'Self-confidence',
        'Personal Finance',
        'Creativity'
      ],
      title: 'In which areas do you want to grow?',
      text: 'The choice won’t limit your experience'
    },
    responsePage: {
      component: 'InformationCard',
      props: {
        imgName: 'joy',
        title: 'Understand big ideas in minutes instead of hours!',
        text: "Cover any book in just 15 minutes with our app. This way, you'll make the most of your time putting your new knowledge into action."
      }
    },
    section: 'Profile'
  },
  {
    name: 'people_selection',
    component: 'MultiSelectWithImagesCard',
    props: {
      options: [
        { img: 'jobs', name: 'Steve Jobs' },
        { img: 'branson', name: 'Richard Branson' },
        { img: 'james', name: 'LeBron James' },
        { img: 'oprah', name: 'Oprah Winfrey' },
        { img: 'watson', name: 'Emma Watson' },
        { img: 'williams', name: 'Serena Williams' },
        { img: 'bezos', name: 'Jeff Bezos' },
        { img: 'hart', name: 'Kevin Hart' },
        { img: 'brown', name: 'Brené Brown' }
      ],
      title: 'Whose life principles, success, and personality inspire you the most?'
    },
    section: 'Profile'
  },
  {
    name: 'personal_view',
    component: 'SelectOneList',
    props: {
      options: [
        {
          icon: '🔭',
          text: 'Big-picture',
          responsePage: {
            component: 'InformationCard',
            props: {
              imgName: 'book_with_glasses',
              title: 'Big-picture thinking aids problem-solving.',
              text: 'Indeed, there’s no right or wrong way, and seeing details is just as important. So we’ll consider this in creating your plan to help you improve your ability to see both the scope and details clearly!'
            }
          }
        },
        {
          icon: '🔬',
          text: 'Detail-oriented',
          responsePage: {
            component: 'InformationCard',
            props: {
              imgName: 'book_with_magnifier',
              title: 'Detail-oriented thinking aids flawless task completion.',
              text: 'Indeed, there’s no right or wrong way, and seeing the big picture is just as important. So we’ll consider this in creating your plan to help you improve your ability to see both the scope and details clearly!'
            }
          }
        },
      ],
      title: 'Are you a big-picture or detail-oriented person?'
    },
    dynamicResponse: true,
    section: 'Profile'
  },
  {
    name: 'know_what_you_want',
    component: 'SelectOneRow',
    props: {
      title: 'You always know what you want, agree?',
      options: [
        {
          text: '👎',
          feedback: {
            title: 'We hear you!',
            text: 'Your selected growth points will guide us in tailoring the most fitting plan for you.'
          }
        },
        {
          text: '🤷‍♂️',
          feedback: {
            title: 'I understand!',
            text: 'Pinpointing your desires can be challenging, but a personalized growth plan will assist in clarifying, prioritizing, and smoothly achieving your goals.'
          }
        },
        {
          text: '👍',
          feedback: {
            title: 'Were you aware people spend more than 4 hours on their phones every day?',
            text: "If you put just 15 mins aside daily for using Blinkist you'll grow your knowledge and reach your goals."
          }
        }
      ],
      isWithFeedback: true
    },
    section: 'Profile'
  },
  {
    name: 'doubt_step',
    component: 'SelectOneRow',
    props: {
      title: 'I tend to doubt myself and my skills if I make a mistake',
      options: [
        {
          text: '👎',
          responsePage: {
            component: 'InformationCard',
            props: {
              imgName: 'glad_with_book',
              title: 'We’re pleased you feel this way!',
              text: 'It’s important to view your mistakes as opportunities for growth rather than sources of insecurity. With your growth plan, you’ll learn how to turn them into valuable lessons for the future.'
            }
          }
        },
        {
          text: '🤷‍♂️',
          responsePage: {
            component: 'InformationCard',
            props: {
              imgName: 'question_on_book',
              title:
                'Hesitating before making a decision is common, but it can be limiting on a daily basis.',
              text: 'We’re here to help you address this!'
            }
          }
        },
        {
          text: '👍',
          responsePage: {
            component: 'InformationCard',
            props: {
              imgName: 'question_on_book',
              title:
                'Hesitating before making a decision is common, but it can be limiting on a daily basis.',
              text: 'We’re here to help you address this!'
            }
          }
        }
      ]
    },
    dynamicResponse: true,
    section: 'Profile'
  },
  {
    name: 'describe_self',
    component: 'SelectOneList',
    props: {
      title: 'Which of the following describes you better?',
      options: [
        {
          icon: '🚀',
          text: 'A leader',
          feedback: {
            title: 'Leader!',
            text: 'The role of a leader can be quite demanding. By spending just 15 minutes a day, you’ll efficiently learn how to enhance your leadership skills.'
          }
        },
        {
          icon: '👣',
          text: 'A Follower',
          feedback: {
            title: 'Self-awareness is crucial for unlocking your potential. 🙏',
            text: 'A follower can be an excellent team player and independent contributor. However, you can transform this by starting to explore yourself to uncover your strengths and areas for growth.'
          }
        },
        {
          icon: '😎',
          text: 'A little bit of both',
          feedback: {
            title: 'Say “no” to traditional labels or roles. 😎',
            text: 'We’ll help you enhance both leadership and team player skills with our library of over 1200 titles.'
          }
        }
      ],
      isWithFeedback: true
    },
    section: 'Profile'
  },
  {
    name: 'decision_making',
    component: 'SelectOneRow',
    props: {
      title: 'I frequently struggle with making quick decisions.',
      options: [
        {
          text: '👎',
          responsePage: {
            component: 'InformationCard',
            props: {
              imgName: 'victory_view',
              title: 'Great job!',
              text: "We admire your decisiveness, as it's a key trait for achieving success.",
            }
          }
        },
        {
          text: '🤷‍♂️',
          responsePage: {
            component: 'InformationCard',
            props: {
              imgName: 'embrace_view',
              title:
                'Hesitating before making a decision is common, but it can be limiting on a daily basis.',
              text: 'We’re here to help you address this!'
            }
          }
        },
        {
          text: '👍',
          responsePage: {
            component: 'InformationCard',
            props: {
              imgName: 'embrace_view',
              title:
                'Hesitating before making a decision is common, but it can be limiting on a daily basis.',
              text: 'We’re here to help you address this!'
            }
          }
        }
      ]
    },
    dynamicResponse: true,
    section: 'Profile'
  },
  {
    name: 'face_challenges',
    component: 'SelectOneRow',
    props: {
      title: 'I often feel like I lack the inner strength to face challenges.',
      options: [
        {
          text: '👎',
          responsePage: {
            component: 'InformationCard',
            props: {
              imgName: 'motivational_view',
              title: 'Motivation is the key to your success!',
              text: "You'll be twice as motivated when your goals are clear. Use your growth plan to keep them in sight, even during tough times."
            }
          }
        },
        {
          text: '🤷‍♂️',
          responsePage: {
            component: 'InformationCard',
            props: {
              imgName: 'motivational_view',
              title: 'Motivation is essential for success!',
              text: 'You’ll find yourself twice as motivated when your goals are clear. Keep them in focus with your growth plan, even when challenges arise.'
            }
          }
        },
        {
          text: '👍',
          responsePage: {
            component: 'InformationCard',
            props: {
              imgName: 'motivational_view',
              title: 'Motivation is essential for success!',
              text: 'You’ll find yourself twice as motivated when your goals are clear. Keep them in focus with your growth plan, even when challenges arise.'
            }
          }
        }
      ]
    },
    dynamicResponse: true,
    section: 'Profile'
  },
  {
    name: 'extrovert_introvert',
    component: 'SelectOneList',
    props: {
      options: [
        {
          icon: '😄',
          text: 'Extrovert',
          responsePage: {
            component: 'InformationCard',
            props: {
              imgName: 'extrovert',
              title: 'Cheers to the extrovert team!',
              text: 'Extroverts need a special approach to find happiness. We consider everything you share with us to tailor your growth plan just for you!'
            }
          }
        },
        {
          icon: '🙂',
          text: 'Introvert',
          responsePage: {
            component: 'InformationCard',
            props: {
              imgName: 'introvert',
              title: 'Cheers to the introvert team!',
              text: 'Introverts need a tailored approach to find happiness. We take all your input into account to create a growth plan that suits you perfectly!'
            }
          }
        },
        {
          icon: '🤔',
          text: 'Both',
          responsePage: {
            component: 'InformationCard',
            props: {
              imgName: 'ambivert',
              title: 'Cheers to the ambivert team!',
              text: 'Ambiverts, who adapt between extroversion and introversion depending on the situation, need a special approach to find happiness.'
            }
          }
        }
      ],
      title: 'How would you describe yourself?'
    },
    dynamicResponse: true,
    section: 'Personality'
  },
  {
    name: 'regular_workouts',
    component: 'SelectOneRow',
    props: {
      title: 'Do you believe that regular workouts lead to better performance at work?',
      options: [
        {
          text: '👎',
          feedback: {
            title: 'It works, we’ve confirmed! 🏋️',
            text: 'Regular workouts are essential for maintaining energy and reaching your career goals. Your growth plan will help you find the right balance to stay energized.'
          }
        },
        {
          text: '🤷‍♂️',
          feedback: {
            title: 'We can customize your growth plan to help you explore further. 👐',
            text: 'Regular workouts can significantly improve your concentration, memory, and stress levels!'
          }
        },
        {
          text: '👍',
          feedback: {
            title: 'We’re on the same wavelength! 🌊',
            text: 'Maintaining a healthy work-life balance, including regular workouts, greatly enhances your ability to stay resourceful.'
          }
        }
      ],
      isWithFeedback: true
    },
    section: 'Personality'
  },
  {
    name: 'friendly_push',
    component: 'SelectOneRow',
    props: {
      title: 'Do you occasionally need a friendly push to stay on track?',
      options: [
        {
          text: '👎',
          responsePage: {
            component: 'InformationCard',
            props: {
              imgName: 'books_on_table',
              title: 'A friendly push isn’t for everyone, and that’s completely okay.',
              text: 'We’ll take your preferences into account when creating your growth plan'
            }
          }
        },
        {
          text: '🤷‍♂️',
          responsePage: {
            component: 'InformationCard',
            props: {
              imgName: 'bell_on_books',
              title: 'A friendly push now and then can definitely be a great motivator.',
              text: 'We’ll take your feelings about it into account when creating your growth plan.'
            }
          }
        },
        {
          text: '👍',
          responsePage: {
            component: 'InformationCard',
            props: {
              imgName: 'bell_on_books',
              title: 'A friendly push now and then can definitely be a great motivator.',
              text: 'We’ll take your feelings about it into account when creating your growth plan.'
            }
          }
        }
      ]
    },
    dynamicResponse: true,
    section: 'Personality'
  },
  {
    name: 'time_management',
    component: 'SelectOneRow',
    props: {
      title: 'Do you feel the need to improve your time management skills?',
      options: [
        {
          text: '👎',
          feedback: {
            title: "Let's concentrate on other areas of your growth for now.",
            text: 'However, if you find time management relevant in the future, we can adjust your plan accordingly.'
          }
        },
        {
          text: '🤷‍♂️',
          feedback: {
            title: 'We all experience this from time to time 🫂',
            text: 'We’re here to help enhance your time-management skills in whatever way you need.'
          }
        },
        {
          text: '👍',
          feedback: {
            title: 'We admire your drive! 💪',
            text: 'Effective time management is key to reaching your life goals, and we’re here to support you in mastering it.'
          }
        }
      ],
      isWithFeedback: true
    },
    section: 'Personality'
  },
  {
    name: 'boundaries_in_relationships',
    component: 'SelectOneRow',
    props: {
      title: 'I frequently find myself losing my boundaries in relationships.',
      options: [
        {
          text: '👎',
          responsePage: {
            component: 'InformationCard',
            props: {
              imgName: 'glad_with_book',
              title: 'That’s great to hear!',
              text: 'Your boundaries are important and valid, and we’re glad you maintain them.'
            }
          }
        },
        {
          text: '🤷‍♂️',
          responsePage: {
            component: 'InformationCard',
            props: {
              imgName: 'question_on_book',
              title:
                'Regardless of the situation, your boundaries are important, and losing them can be painful.',
              text: 'However, there’s always room for improvement! We’ll assist you in working on this through your growth plan.'
            }
          }
        },
        {
          text: '👍',
          responsePage: {
            component: 'InformationCard',
            props: {
              imgName: 'book_reading_girl',
              title:
                'No matter what, your boundaries are important, and losing them can be painful.',
              text: 'However, there’s always room for improvement! We’ll help you address this with your growth plan.'
            }
          }
        }
      ]
    },
    dynamicResponse: true,
    section: 'Personality'
  },
  {
    name: 'sex_life',
    component: 'SelectOneRow',
    props: {
      title: 'Are you pleased with your sex life?',
      options: [{ text: '👎' }, { text: '🤷‍♂️' }, { text: '👍' }]
    },
    dynamicResponse: false,
    section: 'Personality'
  },
  {
    name: 'body_and_desires',
    component: 'SelectOneRow',
    props: {
      title: 'Do you want to gain a better understanding of your body and desires?',
      options: [{ text: '👎' }, { text: '🤷‍♂️' }, { text: '👍' }]
    },
    responsePage: {
      component: 'InformationCard',
      props: {
        imgName: 'girl_looking_into_mirror',
        title: ' Self-reflection is crucial for self-growth!',
        text: 'We’ll assist you in exploring your thoughts, feelings, and actions more deeply to guide you towards becoming a better version of yourself.'
      }
    },
    dynamicResponse: false,
    section: 'Personality'
  },
  {
    name: 'when_succeed_in_something',
    component: 'SelectOneList',
    props: {
      options: [
        {
          icon: '🧐',
          text: 'focusing on what could be improved',
          responsePage: {
            component: 'InformationCard',
            props: {
              imgName: 'woman_with_coffee',
              title: "It's important to recognize areas for improvement!",
              text: "But it's also valuable to “pat yourself on the back” for your successes. Your growth plan will guide you in finding opportunities for growth while celebrating your achievements to keep you motivated on your journey to success."
            }
          }
        },
        {
          icon: '🥳',
          text: 'celebrate the win',
          responsePage: {
            component: 'InformationCard',
            props: {
              imgName: 'man_celebrating',
              title: 'Focusing on the positives is a great motivator.',
              text: 'Your growth plan will help you keep that positive outlook while also recognizing areas for potential improvement on your path to success.'
            }
          }
        }
      ],
      title: 'When you succeed in something, would you prefer to...'
    },
    dynamicResponse: true,
    section: 'Patterns'
  },
  {
    name: 'enjoy_your_job',
    component: 'SelectOneRow',
    props: {
      title: 'Do you enjoy your job?',
      options: [
        {
          text: '👎',
          feedback: {
            title: 'Don’t worry. Many others are in the same boat 🔋',
            text: 'Based on our users’ feedback, burnout and lack of motivation are common issues. We’ll help you identify your challenges and work towards improving your life!'
          }
        },
        {
          text: '🤷‍♂️',
          feedback: {
            title: 'Thinking about making a change? 👐',
            text: 'Switching can be tough, but it’s always achievable with a solid plan. We’ll assist you in focusing on your growth areas to make a meaningful transformation.'
          }
        },
        {
          text: '👍',
          feedback: {
            title: ' It’s fantastic that you’re following your passion! 🎯',
            text: 'Join millions of Sparkle users who have found a better way to enhance their lives in just a few minutes a day.'
          }
        }
      ],
      isWithFeedback: true
    },
    section: 'Patterns'
  },
  {
    name: 'work_life_balance',
    component: 'SelectOneRow',
    props: {
      title: 'Do you keep a healthy work-life balance?',
      options: [
        {
          text: '👎',
          responsePage: {
            component: 'InformationCard',
            props: {
              imgName: 'sleeping',
              title:
                'A healthy work-life balance is essential for staying resourceful and reaching your goals.',
              text: 'Your growth plan will guide you in balancing all aspects of your life to ensure you feel both fulfilled and energized.'
            }
          }
        },
        {
          text: '🤷‍♂️',
          responsePage: {
            component: 'InformationCard',
            props: {
              imgName: 'meditating',
              title: 'We’re glad to hear that!',
              text: 'Maintaining a healthy work-life balance is crucial for staying resourceful and is a key component of success.'
            }
          }
        },
        {
          text: '👍',
          responsePage: {
            component: 'InformationCard',
            props: {
              imgName: 'meditating',
              title: 'We’re glad to hear that!',
              text: 'Maintaining a healthy work-life balance is crucial for staying resourceful and is a key component of success.'
            }
          }
        }
      ]
    },
    dynamicResponse: true,
    section: 'Patterns'
  },
  {
    name: 'professional_potential',
    component: 'SelectOneRow',
    props: {
      title: 'I feel like I have the potential to achieve much more professionally.',
      options: [{ text: '👎' }, { text: '🤷‍♂️' }, { text: '👍' }]
    },
    dynamicResponse: false,
    section: 'Patterns'
  },
  {
    name: 'financial_situation',
    component: 'SelectOneRow',
    props: {
      title: 'Are you satisfied with your financial situation?',
      options: [
        {
          text: '👎',
          feedback: {
            title: 'Thank you for your openness! 🌎',
            text: 'After the quiz, you’ll gain access to insights from top bestsellers on financial management, entrepreneurship, and handling money.'
          }
        },
        {
          text: '🤷‍♂️',
          feedback: {
            title: 'Not sure where to start with improving your finances? 💸',
            text: 'You can find valuable content and practical tips on financial management and entrepreneurship on the Sparkle app.'
          }
        },
        {
          text: '👍',
          feedback: {
            title: 'Glad to hear that! 💪',
            text: "Based on your responses, we'll concentrate on other key areas in your growth plan."
          }
        }
      ],
      isWithFeedback: true
    },
    section: 'Patterns'
  },
  {
    name: 'good_enough',
    component: 'SelectOneRow',
    props: {
      title: 'Do you frequently worry that you’re not good enough?',
      options: [
        {
          text: '👎',
          responsePage: {
            component: 'InformationCard',
            props: {
              imgName: 'girl_on_a_desk',
              title: 'We’re impressed by your confidence!',
              text: 'It’s crucial for success, and we’ll help you elevate it to even greater heights.'
            }
          }
        },
        {
          text: '🤷‍♂️',
          responsePage: {
            component: 'InformationCard',
            props: {
              imgName: 'meditating',
              title: "It's quite common to worry about not being good enough.",
              text: 'However, overcoming this challenge will bring you closer to success. We’ll factor this into your plan to provide you with the best confidence-boosting insights!'
            }
          }
        },
        {
          text: '👍',
          responsePage: {
            component: 'InformationCard',
            props: {
              imgName: 'meditating',
              title: "It's quite common to worry about not being good enough.",
              text: 'However, overcoming this challenge will bring you closer to success. We’ll factor this into your plan to provide you with the best confidence-boosting insights!'
            }
          }
        }
      ]
    },
    dynamicResponse: true,
    section: 'Patterns'
  },
  {
    name: 'compare_yourself',
    component: 'SelectOneRow',
    props: {
      title: 'Do you often compare yourself to others?',
      options: [{ text: '👎' }, { text: '🤷‍♂️' }, { text: '👍' }]
    },
    dynamicResponse: false,
    responsePage: {
      component: 'InformationCard',
      props: {
        imgName: 'bookshelf',
        title: 'Over the last month, our users have read 303,600+ book summaries!',
        text: 'Join our community of avid learners and successfully fit self-growth into your busy life.'
      }
    },
    section: 'Patterns'
  },
  {
    name: 'image_the_art_of_work',
    component: 'ImageLike',
    props: {
      options: ['👎', '👍'],
      img: 'the_art_of_work',
      title: 'Select the books that pique your interest'
    }
  },
  {
    name: 'zero_to_one',
    component: 'ImageLike',
    props: {
      options: ['👎', '👍'],
      img: 'example_book_1',
      title: 'Select the books that pique your interest'
    }
  },
  {
    name: 'art_of_war',
    component: 'ImageLike',
    props: {
      options: ['👎', '👍'],
      img: 'example_book_2',
      title: 'Select the books that pique your interest'
    }
  },
  {
    name: 'atomic_habits',
    component: 'ImageLike',
    props: {
      options: ['👎', '👍'],
      img: 'example_book_3',
      title: 'Select the books that pique your interest'
    }
  },
  {
    name: 'mans_search_for_meaning',
    component: 'ImageLike',
    props: {
      options: ['👎', '👍'],
      img: 'example_book_4',
      title: 'Select the books that pique your interest'
    }
  },
  {
    name: 'social_proof_first',
    component: 'SocialProof',
  },
  {
    name: 'categories',
    component: 'MultiSelectList',
    props: {
      title: 'What categories do you like?',
      subtitle: 'Choose up to 3 categories for more precise personalization',
      options: [
        {
          name: 'Business & Career',
          id: '76mWYbNXqjPEnUY30C0q',
          iconComponentName: 'IconCat1'
        },
        {
          name: 'Entrepreneurship',
          id: '50eVUFF676ctoDxG7ine',
          iconComponentName: 'IconCat2'
        },
        {
          name: 'Money & Investment',
          id: '3NjiNbZ7fbNRCGd8IQul',
          iconComponentName: 'IconCat3'
        },
        {
          id: 'f3ByDrIl3OM8OzGNsCoy',
          name: 'Leadership',
          iconComponentName: 'IconCat4'
        },
        {
          name: 'Productivity',
          id: 'HMKfoK2ll7WjLWWYd1XU',
          iconComponentName: 'IconCat5'
        },
        {
          name: 'Communication Skills',
          id: 'eMD6vHja2BdjD6goyFBL',
          iconComponentName: 'IconCat6'
        },
        {
          name: 'Technology & Future',
          id: 'jUK8a5VvqF5YtMuKPWSZ',
          iconComponentName: 'IconCat7'
        },
        {
          name: 'Self Growth',
          id: 'TeGoKmsCMt1gAoYTAqSQ',
          iconComponentName: 'IconCat8'
        },
        {
          name: 'Creativity',
          id: 'Me980EskZKrY8eHbUchf',
          iconComponentName: 'IconCat9'
        },
        {
          name: 'Happiness',
          id: 'LIV3AQ5seK0xa5w9gB00',
          iconComponentName: 'IconCat10'
        },
        {
          name: 'Love & Sex',
          id: 'eco4732JGYzc59PKbCUh',
          iconComponentName: 'IconCat11'
        },
        {
          name: 'Psychology',
          id: 'CuYDjrntJ8izaRRF3C8Y',
          iconComponentName: 'IconCat12'
        },
        {
          name: 'Spirituality',
          id: '8c3GkS9XLYzkJRlrWjF0',
          iconComponentName: 'IconCat13'
        },
        {
          name: 'Family',
          id: '2f5bq7kllLPimQw780Nh',
          iconComponentName: 'IconCat14'
        },
        {
          name: 'Society & Culture',
          id: 'vHwjNkCUSYN28W6fppGK',
          iconComponentName: 'IconCat15'
        },
        {
          name: 'Nature & Environment',
          id: 'R79qvCmbd662BlnW6HTM',
          iconComponentName: 'IconCat16'
        },
        {
          name: 'Health & Nutrition',
          id: 'EmihPGxqTDUqd1STto7b',
          iconComponentName: 'IconCat17'
        },
        {
          name: 'Sports & Fitness',
          id: 'VvJDH3lgxJRfd2M57glu',
          iconComponentName: 'IconCat18'
        }
      ],
      maxSelections: 3
    },
  },
  {
    name: 'self_development_daily',
    component: 'SelectOneList',
    props: {
      options: [

        { icon: '🤙', text: 'Easy', subtext: '5 min/day', },
        { icon: '👌', text: 'Common', subtext: '10 min/day' },
        { icon: '🤘', text: 'Serious', subtext: '15 min/day' },
        { icon: '💪', text: 'Intensive', subtext: '20+ min/day' }
      ],
      title: 'Set your goal for timing',
      text: 'Choose how much time you’ll dedicate to self-development each day.'
    }
  },
  {
    name: 'loading_pop_ups',
    component: 'LoadingPopUps',
    props: {
      title: 'Creating your personal experience...',
      popUpHeader: 'To make progress, please clarify',
      imgName: 'open_book',
      quote: {
        text: '"<span class="primary-color">Sparkle</span> is a tool for acquiring and absorbing as much information as possible”',
        author: 'Fig Nelson',
        imgName: 'fig_nelson_person'
      },
      items: [
        { icon: '🎯', title: 'Settings Goals' },
        { icon: '📈', title: 'Defining Growth Areas' },
        { icon: '📚', title: 'Searching Contents' },
        { icon: '⚔️', title: 'Adjust Challenges' }
      ],
      hasModal: true,
      options: ['No', 'Yes'],
      questions: [
        {
          title: 'Do you engage in self reflection?',
          qID: 'engage_self_reflection'
        },
        {
          title: 'Do you feel like giving up when things get tough?',
          qID: 'give_up_when_tough'

        },
        {
          title: 'Do you prefer to learn by listening?',
          qID: 'learn_by_listening'
        },
        {
          title: 'Are you inclined to see things through to completion?',
          qID: 'inclined_to_see'
        }
      ]
    }
  },
  {
    name: 'email_form',
    component: 'EmailForm'
  },
  {
    name: 'plan_page',
    component: 'PlanPage'
  },
  {
    name: 'social_proof_second',
    component: 'SocialProof2'
  },
]
export const QUIZ_ID = 'onboarding-1'

export const onboardings = {
  'onboarding-1': {
    steps: steps,
    discountId: discount,
    discountRate: 0.6,
    currency: 'USD',
  },
}