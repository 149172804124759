<script setup>
import { ref } from 'vue'
import FeedbackCard from '../atoms/FeedbackCard.vue'

const { title, options, propSelectedIndex, isWithFeedback } = defineProps([
  'title',
  'options',
  'selectedIndex',
  'isWithFeedback'
])

const selectedIndex = ref(propSelectedIndex)
</script>
<template>
  <div class="container">
    <p class="heading-5">{{ title }}</p>
    <FeedbackCard
      class="mt-30"
      v-if="isWithFeedback && selectedIndex !== undefined && options[selectedIndex].feedback"
      :title="options[selectedIndex].feedback.title"
      :text="options[selectedIndex].feedback.text"
    />
    <div class="options-container mt-16">
      <button
        :class="`btn btn-fluid heading-5 mt-8 ${selectedIndex == index ? 'selected' : ''}`"
        v-for="(option, index) in options"
        :key="option"
        @click="
          () => {
            selectedIndex = index
            if (!isWithFeedback) $emit('selection', index)
          }
        "
      >
        {{ option.text || option }}
      </button>
    </div>
    <button
      v-if="isWithFeedback"
      class="btn btn-primary label-md cnt-btn mt-16"
      :disabled="selectedIndex === undefined"
      @click="$emit('selection', selectedIndex)"
    >
      Continue
    </button>
  </div>
</template>
<style scoped>
.options-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 8px;
}
.options-container button {
  flex-grow: 1;
}
</style>
