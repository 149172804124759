<script setup>
import { ref, computed } from 'vue'

const { title, text, options } = defineProps(['title', 'text', 'options'])

const emit = defineEmits(['selection'])
const selectionStatuses = ref(options.map(() => false))

const isAnySelected = computed(() => {
  const ret = selectionStatuses.value.some((status) => status)
  return ret
})

const select = (index) => {
  selectionStatuses.value[index] = !selectionStatuses.value[index]
}

const emitSelection = () => {
  const selectedOptions = selectionStatuses.value
    .map((selected, i) => (selected ? options[i] : null))
    .filter((option) => option !== null)
  emit('selection', selectedOptions)
}
</script>

<template>
  <div class="container">
    <p class="heading-5">{{ title }}</p>
    <p class="p-md">{{ text }}</p>
    <div class="options-container mt-16">
      <button
        class="btn btn-sm btn-fluid p-md"
        :class="{ selected: selectionStatuses[index] }"
        v-for="(option, index) in options"
        :key="option"
        @click="select(index)"
      >
        {{ option }}
      </button>
    </div>
    <button
      class="btn btn-primary label-md cnt-btn mt-16"
      :disabled="!isAnySelected"
      @click="emitSelection"
    >
      Continue
    </button>
  </div>
</template>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.options-container {
  display: flex;
  gap: 2px;
  flex-wrap: wrap;
  justify-content: center;
}

.btn-fluid {
  width: unset;
}

.btn {
  padding: 12px;
}
</style>
