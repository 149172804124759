<script setup>
import Divider from '../atoms/Divider.vue'

const { imgName, user, comment, date } = defineProps(['imgName', 'user', 'comment', 'date'])
const imageSrc = new URL(`../../assets/images/${imgName}`, import.meta.url).href
</script>

<template>
  <div class="container">
    <div class="user">
      <img class="avatar" :src="imageSrc" alt="avatar" />
      <div class="name labe-xs">{{ user }}</div>
    </div>
    <div class="comment p-sm">{{ comment }}</div>
    <Divider />
    <div class="date p-xs">{{ date }}</div>
  </div>
</template>

<style scoped>
.container {
  padding: 24px;
  border-radius: 20px;
  background: var(--neutrals-0);
  border: 1px solid var(--neutrals-200);
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.user {
  display: flex;
  align-items: center;
  gap: 8px;
}
.avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.comment {
  white-space: pre-wrap;
  color: var(--neutrals-500);
  text-align: left;
}

.date {
  color: var(--neutrals-400);
  text-align: left;
}
</style>
