import router from "../router";
import { useUserStore } from "../store/user";
import { getCerebroId, sendEvent } from "./cerebro";
import { isStaging } from "./environment";
import { requestWithIdToken } from "./requestWithIdToken";
import { calculateRevenue } from "@/helpers/event";
import { sleep } from "@/helpers/general";
import verifyPayment from '@/helpers/paymentVerification'
import { getSessionId } from './sessionManager'
import { sendGTagEvent } from '@/helpers/gtag'

if (isStaging) {
  Paddle.Environment.set("sandbox");
}
const initializeOptions = {
  token: import.meta.env.VITE_PADDLE_CLIENT_TOKEN,
  checkout: {
    settings: {
      displayMode: "inline",
      theme: "light",
      locale: "en",
      frameTarget: "checkout-container",
      frameInitialHeight: "450",
      frameStyle:
        "width: 100%; min-width: 120px; background-color: transparent; border: none;",
    },
  },
  eventCallback: async function (event) {
    if (isStaging) {
      console.log("paddle eventCallback:", event);
    }
    const userStore = useUserStore();

    if (
      event.name == "checkout.loaded" ||
      event.name == "checkout.items.updated"
    ) {
      // capture totals
      var todayTotal = event.data.totals.total;
      var recurringTotal = event.data.recurring_totals.total;
      var oneTimeTotal = todayTotal - recurringTotal;
      var currencyCode = event.data.currency_code;

      if (isStaging) {
        console.log("Today's total: " + recurringTotal + " " + currencyCode);
      }
      sendGTagEvent({ event: 'purchase_started' })
    } else if (event.name == "checkout.payment.initiated") {
      userStore.editUserData({ isPaymentInitiated: true });
      const price = event.data.totals.total === 0 ? event.data.recurring_totals.total : event.data.totals.total;
      sendEvent({
        eventName: "subscribe_click",
        properties: {
          productIdentifier: event.data.items[0].product.id,
          price: price,
          revenue: calculateRevenue(price, "paddle"),
          paymentMethodType: event.data.payment?.method_details?.card != null ? "card" : event.data.payment?.method_details?.type ?? null,
          paymentCardType: event.data.payment?.method_details?.type === "card" ? event.data.payment?.method_details?.card?.type : null,
          paymentProvider: "paddle",
        },
      });
    } else if (event.name == "checkout.payment.failed" || event.name == 'checkout.error'
    ) {
      userStore.editUserData({ isPaymentInitiated: false });
      sendGTagEvent({ event: 'purchase_failed' })
      sendEvent({
        eventName: "purchase_failed",
        properties: {
          productId: event.data.items[0].product.id,
          payment_method: event.data.payment?.method_details?.type ?? null,
          card_type: event.data?.payment?.method_details?.card?.type ?? '',
          card_last_4: event.data?.payment?.method_details?.card?.last4 ?? '',
          customer_country: event.data?.customer?.address?.country_code ?? '',
          currency: event.data.currency_code,
          error: event?.error?.type ?? 'paddle_error',
          errorCode: event?.error?.code ?? 'paddle',
          errorMessage: event?.error?.detail ?? 'Paddle payment failed',
          discount: event.data?.discount?.id || '',
          source:
            (event.data?.totals?.discount || 0) > 0
              ? 'countdown_discount_1'
              : 'default',
        },
      });
    } else if (event.name == "checkout.completed") {
      userStore.editUserData({ isPaymentInitiated: false });
      router.push({
        name: 'PaymentResult',
        query: {
          success: true,
          cs: 'paddle',
          transactionId: event.data.transaction_id,
          customerId: event.data.customer.id,
          customerEmail: event.data?.customer?.email ?? '',
          productId: event.data.items[0].price_id,
          pv:
            (!event.data.items[0].billingCycle
              ? event.data.totals.total
              : event.data.recurring_totals.total) * 100,
          currency: event.data.currency_code,
          paymentMethod: event.data.payment?.method_details?.type ?? null,
          cardType: event.data?.payment?.method_details?.card?.type ?? '',
          cardLast4: event.data?.payment?.method_details?.card?.last4 ?? '',
          billingCycle: `${event.data?.items[0]?.billing_cycle?.frequency ?? ''}_${event.data?.items[0]?.billing_cycle?.interval ?? ''}`,
          customerCountry: event.data?.customer?.address?.country_code ?? '',
          subtotal: event.data?.totals?.subtotal ?? 0,
          tax: event.data?.totals?.tax ?? 0,
          discount: event.data?.discount?.id || '',
          discountApplied: (event.data?.totals?.discount || 0) > 0 ? '1' : '0',
          discountAmount: event.data?.totals?.discount || 0,
          src:
            (event.data?.totals?.discount || 0) > 0
              ? 'countdown_discount_1'
              : 'default',
          sessionId: await getSessionId(),
          revenue: event.data?.totals?.total - event.data?.totals?.tax,
        },
      })



      if (isStaging) {
        console.log("Checkout completed", event.data);
      }
    } else if (
      event.name == "checkout.customer.created" ||
      event.name == "checkout.customer.updated"
    ) {
      const userEmail = userStore.user?.email
      const paddleCustomerEmail = event.data.customer.email
      if (userEmail != paddleCustomerEmail) {
        userStore.setEmailMismatch(true)
      }
      const updateData = {
        customerId: event.data.customer.id,
        paddleCustomerEmail: event.data.customer.email,
        userId: userStore.user?.uid,
        cerebroId: getCerebroId(),
      };
      const response = await requestWithIdToken({
        path: "/paddle/customer-update",
        method: "POST",
        data: updateData,
      });
      if (response.data.success != true) {
        sendEvent({
          eventName: "paddle_customer_update_failed",
          event_group: "error",
          properties: updateData,
        });
      } else {
        userStore.editUserData({
          paddleCustomerId: updateData.customerId,
          paddleCustomerEmail: updateData.paddleCustomerEmail,
        });
        sendEvent({
          eventName: 'paddle_customer_update',
          event_group: 'error',
          properties: {
            customer_id: updateData.customerId,
            paddle_customer_email: updateData.paddleCustomerEmail,
            cerebro_id: updateData.cerebroId,
          },
        })
        initializePaddle();
      }
    }
  },
};

export const initializePaddle = (pci) => {
  const userStore = useUserStore();

  const paddleCustomerId = pci || userStore.userData?.paddleCustomerId;
  if (Paddle.Initialized) {
    if (paddleCustomerId) {
      Paddle.Update({
        pwCustomer: {
          id: paddleCustomerId,
        },
      });
      if (isStaging) {
        console.log("updated paddle with customer id: ", paddleCustomerId);
      }
    }
  } else {
    if (paddleCustomerId) {
      Paddle.Initialize({
        ...initializeOptions,
        pwCustomer: {
          id: paddleCustomerId,
        },
      });
      if (isStaging) {
        console.log("initialized paddle with customer id: ", paddleCustomerId);
      }
    } else {
      Paddle.Initialize(initializeOptions);
      if (isStaging) {
        console.log("initialized paddle with default options");
      }
    }
  }
};

// const validatePaddlePurchase = async (trxId, count = 0) => {
//   console.log("Validating paddle purchase", trxId + count);
//   const response = await requestWithIdToken({
//     path: "/paddle/verify-purchase",
//     method: "POST",
//     data: {
//       trxId,
//     },
//   });
//   if (response.data.success == false) {
//     if (count < 10) {
//       setTimeout(() => {
//         validatePaddlePurchase(trxId, count + 1);
//       }, 5000);
//     }
//   } else {
//     console.log(response.data);
//   }
// };


export const validatePurchase = async (trxId, count = 0) => {
  const userStore = useUserStore();
  const verificationResponse = await verifyPayment({ transactionId: trxId })
  if (verificationResponse) {
    userStore.editUserData({ subscription: verificationResponse })
    userStore.updateFirebaseUserData()
    return 'success'
  } else {
    if (count < 20) {
      await sleep(500);
      return await validatePurchase(trxId, count + 1);
    } else {
      userStore.editUserData({ subscription: null })
      userStore.updateFirebaseUserData()
      return null
    }
  }
};

export const fetchPaddleProducts = async (
  productIds,
) => {

  try {
    const products = await Paddle.PricePreview({ items: productIds });
    if (!products.data || !products.data.details || !products.data.details.lineItems || products.data.details.lineItems.length === 0) {
      sendEvent({
        eventName: 'paywall_products_failed_to_load',
      })
      return [];
    }
    return formatPricesLikeStripe(products.data.details.lineItems);
  } catch (error) {
    sendEvent({
      eventName: 'paywall_products_failed_to_load',
    })
    console.log(error);
    return [];
  }
};

const formatPricesLikeStripe = (prices) => {
  return prices.map((priceItem) => {
    return {
      id: priceItem.price.id,
      object: "price",
      currency: priceItem.price.unitPrice.currencyCode,
      nickname: priceItem.price.name,
      product: priceItem.product.id,
      offersFreeTrial: priceItem.price.trialPeriod?.frequency != null && priceItem.price.trialPeriod?.interval != null,
      recurring: {
        interval: priceItem.price.billingCycle.interval,
        interval_count: priceItem.price.billingCycle.frequency,
        trial_period_frequency: priceItem.price.trialPeriod?.frequency,
        trial_period_interval: priceItem.price.trialPeriod?.interval,
        usage_type: "licensed",
      },
      type: "recurring",
      unit_amount: Number(priceItem.unitTotals.total),
      unit_amount_decimal: priceItem.unitTotals.total,
    };
  });
};
